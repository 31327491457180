<template >
	<div class="views-finish-page-wrapper">
		<div class="row">
			<div class="col-12 mb-2 text-center">
				<appIconVue size="96" radius="24" style="opacity: 0" ref="appIcon" />
			</div>
			<div class="col-12 mt-4 mb-4 text-center">
				<div
					class="views-finish-userInfo"
					:style="{
						'--color':
							'rgb(' +
							nameToColor[0] +
							',' +
							nameToColor[1] +
							',' +
							nameToColor[2] +
							')',
					}"
				>
					<i class="fa-solid fa-circle-user"></i
					>{{ displayName ?? "未知顯示名稱" }}
				</div>
			</div>
			<div class="col-12 mt-2 mb-2">
				<blockquote v-show="finishStatus == 'Success'">
					我們已經為您建立了屬於您的帳號，您可以使用
					{{ email }}
					登入本應用。
				</blockquote>
				<blockquote v-show="finishStatus == 'SuccessWithLogin'">
					我們已經為您建立了屬於您的帳號，並幫您於此裝置進行登入，您可以立即開始使用應用，未來您可以使用
					{{ email }} 進行登入。
				</blockquote>
				<blockquote>
					如果您在未來的使用過程中遇到任何問題，歡迎您與我們的團隊進行聯絡。
				</blockquote>
			</div>
			<div class="col-12 mt-4 text-center text-color-sub">
				<span v-show="finishStatus == 'Success'"
					><small
						><i class="fa-solid fa-clock fa-fade"></i> 稍後返回登入畫面</small
					></span
				>
				<span v-show="finishStatus == 'SuccessWithLogin'"
					><small
						><i class="fa-solid fa-clock fa-fade"></i> 準備重新載入應用</small
					></span
				>
			</div>
		</div>
	</div>
</template>

<script>
import globalUtils from "@/utils/globalUtils";
import appIconVue from "@/components/global/appIcon.vue";
import router from "@/router";
export default {
	name: "view-ui-register-finish",
	props: [],
	data() {
		return {};
	},
	components: { appIconVue },
	computed: {
		appIcon: {
			get() {
				var vm = this;
				return vm.$refs.appIcon;
			},
		},
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.parentVM.model.displayName;
			},
		},
		finishStatus: {
			get() {
				var vm = this;
				return vm.parentVM.model.finishStatus;
			},
		},
		nameToColor: {
			get() {
				var vm = this;
				if (vm.displayName.length < 3 || vm.displayName.length > 12)
					return [0, 0, 0];
				return globalUtils.getNameToColor(vm.displayName);
			},
		},
	},
	watch: {},
	methods: {
		setInital() {
			setTimeout(() => {
				router.push({ name: "startup", replace: true });
			}, 5 * 1000);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-finish-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-finish-page-wrapper {
		padding-bottom: 3rem;
	}
}
.views-finish-userInfo {
	position: relative;
	width: 240px;
	font-weight: bold;
	display: inline;
	padding: 0.35rem 0.75rem;
	background: #fff;
	color: #000;
	border: solid 3px var(--color);
	border-radius: 36px;
}
.views-finish-userInfo::before {
	content: "";
	border-radius: 36px;
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		-45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	animation: shadow 20s linear infinite;
	background-size: 400%;
	z-index: -1;
	opacity: 0;
	transition: 0.5s;
}
.views-finish-userInfo::after {
	content: "";
	border-radius: 36px;
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	background-size: 400%;
	z-index: -1;
	animation: shadow 20s linear infinite;
	filter: blur(8px);
	opacity: 0;
	transition: 0.5s;
}
.views-finish-userInfo:hover {
	border: solid 0 rgba(100, 100, 255, 0);
	transition: 0s;
}
.views-finish-userInfo:hover::before {
	opacity: 1;
	transition: 0.35s;
}
.views-finish-userInfo:hover::after {
	opacity: 0.5;
	transition: 0.35s;
}
.views-finish-userInfo svg {
	margin-right: 0.35rem;
	color: var(--color);
}
</style>
