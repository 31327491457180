<template>
	<div class="component-appicon-wrapper">
		<div
			class="component-appicon-icon-wrapper"
			:style="{
				width: (size ?? 128) + 'px',
				height: (size ?? 128) + 'px',
				transition: (trans ?? 0) + 's',
			}"
		>
			<div
				class="rainbow-shadow"
				:style="{
					'--borderRadius': (radius ?? 8) + 'px',
				}"
			></div>
			<div
				class="rainbow-border"
				:style="{
					'--borderRadius': (radius ?? 8) + 'px',
				}"
			></div>
			<div
				class="component-appicon-icon-bg"
				:style="{
					'--borderRadius': (radius ?? 8) + 'px',
				}"
			></div>
			<div
				class="component-appicon-icon-mask"
				:style="{
					'--borderRadius': (radius ?? 8) + 'px',
				}"
			></div>
			<div class="component-appicon-icon-img-wrapper">
				<img
					src="@/assets/icon.png"
					:style="{
						width: (imgSize ?? 128) + 'px',
						height: (imgSize ?? 128) + 'px',
					}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "appIcon",
	props: ["size", "radius", "trans"],
	data() {
		return {
			status: "",
			imgSize: 128,
			imgRadius: 32,
		};
	},
	computed: {},
	watch: {
		size() {
			var vm = this;
			vm.calSize();
		},
	},
	methods: {
		calSize() {
			var vm = this;
			vm.imgSize = vm.size;
			vm.imgRadius = vm.radius;
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		vm.calSize();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-appicon-wrapper {
	display: inline-block;
	pointer-events: none;
}
.component-appicon-icon-wrapper {
	position: relative;
	width: 128px;
	height: 128px;
}
.component-appicon-icon-img-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	justify-content: center;
	align-items: center;
}
.component-appicon-icon-mask {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	--smooth-corners: 5;
	mask-image: url("/src/assets/iconMask.png"), paint(smooth-corners);
	mask-size: cover;
	mask-composite: exclude;
	background: black;
}
@-moz-document url-prefix() {
	.component-appicon-icon-mask {
		mask-image: paint(smooth-corners), url("/src/assets/iconMask.png");
	}
}
.component-appicon-icon-bg {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	--smooth-corners: 5;
	mask-image: paint(smooth-corners);
	-webkit-mask-image: paint(smooth-corners);
	/* border-radius: var(--borderRadius); */
}
.rainbow-border {
	position: absolute;
	top: -4px;
	bottom: -4px;
	left: -4px;
	right: -4px;
	overflow: hidden;
	--smooth-corners: 5;
	mask-image: paint(smooth-corners);
	-webkit-mask-image: paint(smooth-corners);
	/* border-radius: calc(var(--borderRadius) + 4px); */
}
.rainbow-border::before {
	top: 0;
	left: 0;
	width: calc(100%);
	height: calc(100%);
	filter: blur(12px);
}
.rainbow-shadow {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: var(--borderRadius);
}
.rainbow-shadow::after {
	top: -3px;
	left: -3px;
	width: calc(100% + 6px);
	height: calc(100% + 6px);
	border-radius: var(--borderRadius);
	opacity: 1;
}
</style>
