<template >
	<div class="views-email-page-wrapper">
		<div class="row">
			<div class="col-12 mb-2 text-center">
				<appIconVue size="96" radius="32" ref="appIcon" style="opacity: 0" />
			</div>
			<div class="col-12 mt-2 mb-4 text-center">
				<h1 class="m-0"><strong>Cash Tracert</strong></h1>
				<small class="text-color-sub">在您手上的隨身記帳助理</small>
			</div>
			<div class="col-12 mt-2 mb-2 text-center">
				請輸入您的 Email 以進行登入或註冊。
			</div>
			<div class="col-12 mt-2 mb-2 text-center">
				<div class="row">
					<div class="col-1 col-md-2"></div>
					<div class="col-10 col-md-8">
						<input
							class="tb-normal full-width"
							placeholder="輸入 Email 開始使用"
							type="email"
							@focus="$event.target.select()"
							@keyup="onEmailKeyUp"
							v-model="email"
						/>
					</div>
				</div>
			</div>
			<div class="col-12 mt-5 text-center">
				<button
					class="btn-normal"
					:class="[sendButtonStatus.color]"
					:disabled="sendButtonStatus.disabled"
					@click="onSendBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="sendButtonStatus.text">
							<i
								v-show="sendButtonStatus.icon == 'normal'"
								class="fa-solid fa-arrow-right"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'login'"
								class="fa-solid fa-right-to-bracket"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'wait'"
								class="fa-solid fa-arrows-rotate fa-spin"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'register'"
								class="fa-solid fa-user-plus"
							></i>
							{{ sendButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import appIconVue from "@/components/global/appIcon.vue";
import regexCheck from "@/utils/regexCheck";
import loginApi from "@/api/login";
import msgCtrl from "@/utils/msgCtrl";
import moment from "moment";
export default {
	name: "view-ui-login-email",
	props: [],
	data() {
		return {
			dispatchCheckEmail: null,
			sendButtonStatus: {
				color: "primary",
				text: "讓我們開始吧",
				icon: "normal",
				disabled: true,
				status: "",
			},
			buttonList: {
				normal: {
					color: "primary",
					text: "讓我們開始吧",
					icon: "normal",
					disabled: true,
					status: "",
				},
				login: {
					color: "primary",
					text: "使用 Email 進行登入",
					icon: "login",
					disabled: false,
					status: "login",
				},
				wait: {
					color: "",
					text: "請稍後",
					icon: "wait",
					disabled: true,
					status: "wait",
				},
				register: {
					color: "success",
					text: "使用 Email 註冊",
					icon: "register",
					disabled: false,
					status: "register",
				},
			},
		};
	},
	components: { appIconVue },
	computed: {
		appIcon: {
			get() {
				var vm = this;
				return vm.$refs.appIcon;
			},
		},
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.email = value;
			},
		},
		verifyUID: {
			get() {
				var vm = this;
				return vm.parentVM.model.verifyUID;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.verifyUID = value;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.parentVM.model.displayName;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.displayName = value;
			},
		},
	},
	watch: {
		email() {
			var vm = this;
			vm.onEmailChange();
		},
	},
	methods: {
		setInital() {
			var vm = this;
			vm.setSendButtonStatus("normal");
			vm.callCheckEmail();
		},
		setSendButtonStatus(name) {
			var vm = this;
			vm.sendButtonStatus = JSON.parse(JSON.stringify(vm.buttonList[name]));
		},
		onEmailChange() {
			var vm = this;
			if (!regexCheck.regexCheckEmail(vm.email)) {
				vm.setSendButtonStatus("normal");
				return;
			}
			if (vm.dispatchCheckEmail != null) vm.dispatchCheckEmail();
		},
		onEmailKeyUp(e) {
			var vm = this;
			if (e.key === "Enter") {
				if (vm.sendButtonStatus.status == "login") vm.callLoginUser();
				if (vm.sendButtonStatus.status == "register")
					vm.parentVM.switchToRegister(vm.email);
			}
		},
		onSendBtnClick() {
			var vm = this;
			if (vm.sendButtonStatus.status == "login") vm.callLoginUser();
			if (vm.sendButtonStatus.status == "register")
				vm.parentVM.switchToRegister(vm.email);
		},
		callCheckEmail() {
			var vm = this;

			vm.setSendButtonStatus("wait");
			let dateNow = moment();
			loginApi
				.checkEmail({
					Email: vm.email,
				})
				.then((res) => {
					let waitTime = 1000 - moment().diff(dateNow, "milliseconds");
					if (waitTime < 0) waitTime = 0;

					setTimeout(() => {
						if (!regexCheck.regexCheckEmail(vm.email)) {
							vm.setSendButtonStatus("normal");
							return;
						}
						if (res.Success) {
							if (res.Action == "Login") {
								vm.setSendButtonStatus("login");
								return;
							}
							if (res.Action == "Register") {
								vm.setSendButtonStatus("register");
								return;
							}
							vm.setSendButtonStatus("normal");
						} else {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"檢測失敗",
								"進行 Email 檢查時發生錯誤，請稍後再重試",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("normal");
						}
					}, waitTime);
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行 Email 檢查時發生錯誤，請稍後再重試",
						msgCtrl.notifyDelay.default
					);
					vm.setSendButtonStatus("normal");
				})
				.finally(() => {});
		},
		callLoginUser() {
			var vm = this;

			if (!regexCheck.regexCheckEmail(vm.email)) {
				vm.setSendButtonStatus("normal");
				return;
			}

			vm.setSendButtonStatus("wait");
			loginApi
				.loginUser({
					Email: vm.email,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "NotEmail") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入錯誤",
								"目前輸入的 Email 帳號不符合格式，如果錯誤持續發生，請重新整理並再次重試",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("normal");
							return;
						}

						if (res.Action == "Register") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"尚未註冊",
								"目前輸入的 Email 尚未註冊，請開啟註冊界面，我們將為您註冊屬於您的帳號",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("register");
							return;
						}

						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入失敗",
								"登入的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("login");
							return;
						}
						if (res.Action == "Send") {
							vm.verifyUID = res.Data[0];
							vm.displayName = res.Data[1];
							vm.parentVM.setPage("verify");
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"登入失敗",
							"進行登入時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
							msgCtrl.notifyDelay.default
						);
						vm.setSendButtonStatus("login");
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線登入時發生錯誤，請稍後再重試，如果問題持續發生，請聯絡我們",
						msgCtrl.notifyDelay.default
					);
					vm.setSendButtonStatus("normal");
				})
				.finally(() => {});
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		vm.dispatchCheckEmail = _.debounce(vm.callCheckEmail, 150);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-email-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-email-page-wrapper {
		padding-bottom: 6rem;
	}
}
</style>
