<template >
	<div class="views-verify-page-wrapper">
		<div class="row">
			<div class="col-12 mb-2 text-center">
				<i class="fa-solid fa-user-shield fa-6x"></i>
			</div>
			<div class="col-12 text-center mt-4 mb-4">
				<div
					class="views-verify-userInfo"
					:style="{
						'--color':
							'rgb(' +
							nameToColor[0] +
							',' +
							nameToColor[1] +
							',' +
							nameToColor[2] +
							')',
					}"
				>
					<i class="fa-solid fa-circle-user"></i>{{ displayName }}
				</div>
			</div>
			<div class="col-12 mt-2 mb-2">
				<blockquote>
					我們已經發送了一封信件到您的信箱，信件中包含您本次的登入驗證碼。
				</blockquote>
				<blockquote>
					請將信件中的驗證碼填寫到下方，填寫完成之後請點擊「進行驗證」
				</blockquote>
			</div>
			<div class="col-12 mt-2 mb-2 text-center">
				<input
					class="tb-verify"
					maxlength="1"
					ref="key1"
					data-key="1"
					v-model="activeKey1"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key2"
					data-key="2"
					v-model="activeKey2"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key3"
					data-key="3"
					v-model="activeKey3"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key4"
					data-key="4"
					v-model="activeKey4"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key5"
					data-key="5"
					v-model="activeKey5"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key6"
					data-key="6"
					v-model="activeKey6"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
			</div>

			<div class="col-12 text-center mt-5">
				<button
					class="btn-normal"
					:class="[sendButtonStatus.color]"
					:disabled="sendButtonStatus.disabled"
					@click="onSendBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="sendButtonStatus.text">
							<i
								v-show="sendButtonStatus.icon == 'normal'"
								class="fa-solid fa-arrow-right"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'verify'"
								class="fa-solid fa-check-double"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'wait'"
								class="fa-solid fa-arrows-rotate fa-spin"
							></i>
							{{ sendButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import globalUtils from "@/utils/globalUtils";
import loginApi from "@/api/login";
import msgCtrl from "@/utils/msgCtrl";
import moment from "moment";
export default {
	name: "view-ui-login-verify",
	props: [],
	data() {
		return {
			sendButtonStatus: {
				color: "primary",
				text: "進行驗證",
				icon: "normal",
				disabled: true,
			},
			buttonList: {
				normal: {
					color: "primary",
					text: "進行驗證",
					icon: "normal",
					disabled: true,
				},
				verify: {
					color: "primary",
					text: "進行驗證",
					icon: "verify",
					disabled: false,
				},
				wait: {
					color: "",
					text: "請稍後",
					icon: "wait",
					disabled: true,
				},
			},
		};
	},
	components: {},
	computed: {
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.parentVM.model.displayName;
			},
		},
		verifyUID: {
			get() {
				var vm = this;
				return vm.parentVM.model.verifyUID;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.verifyUID = value;
			},
		},
		activeKey1: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key1;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key1 = value.toUpperCase();
			},
		},
		activeKey2: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key2;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key2 = value.toUpperCase();
			},
		},
		activeKey3: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key3;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key3 = value.toUpperCase();
			},
		},
		activeKey4: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key4;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key4 = value.toUpperCase();
			},
		},
		activeKey5: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key5;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key5 = value.toUpperCase();
			},
		},
		activeKey6: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key6;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key6 = value.toUpperCase();
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.activeKey1 +
					vm.activeKey2 +
					vm.activeKey3 +
					vm.activeKey4 +
					vm.activeKey5 +
					vm.activeKey6
				);
			},
		},
		isCanVerify: {
			get() {
				var vm = this;
				let result = true;
				if (vm.activeKey1 == null || vm.activeKey1 == "") result = false;
				if (vm.activeKey2 == null || vm.activeKey2 == "") result = false;
				if (vm.activeKey3 == null || vm.activeKey3 == "") result = false;
				if (vm.activeKey4 == null || vm.activeKey4 == "") result = false;
				if (vm.activeKey5 == null || vm.activeKey5 == "") result = false;
				if (vm.activeKey6 == null || vm.activeKey6 == "") result = false;

				return result;
			},
		},
		nameToColor: {
			get() {
				var vm = this;
				return globalUtils.getNameToColor(vm.displayName);
			},
		},
	},
	watch: {
		isCanVerify(newValue) {
			var vm = this;
			if (newValue) vm.setSendButtonStatus("verify");
			else vm.setSendButtonStatus("normal");
		},
	},
	methods: {
		setInital() {
			var vm = this;
			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";
			setTimeout(() => {
				vm.$refs["key1"].focus();
			}, 500);
		},
		setSendButtonStatus(name) {
			var vm = this;
			vm.sendButtonStatus = JSON.parse(JSON.stringify(vm.buttonList[name]));
		},
		onPaste(e) {
			var vm = this;
			let input = null;

			let clipboardData = null;
			try {
				clipboardData = e.clipboardData;
			} catch {
				clipboardData = null;
			}

			if (!clipboardData) clipboardData = e.originalEvent.clipboardData;
			input = clipboardData.getData("Text");
			if (input == null) return;

			input = input.toUpperCase().trim();
			if (input.length != 6) return;
			[...input].forEach((x, i) => {
				vm["activeKey" + (i + 1)] = x;
			});
			vm.$refs["key6"].focus();
		},
		onActiveKeyDown(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value != "") return;
				if (keyNow == 1) return;
				let backName = "key" + (keyNow - 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onActiveKeyUp(e) {
			var vm = this;
			if (e.key === "Enter" && vm.isCanVerify) {
				vm.callCheckActiveKey();
				return;
			}
			if (e.key === "Backspace" || e.key === "Delete") {
				return;
			} else {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value == "") return;
				if (keyNow == 6) return;
				let backName = "key" + (keyNow + 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onSendBtnClick() {
			var vm = this;
			if (vm.isCanVerify) vm.callCheckActiveKey();
		},
		callCheckActiveKey() {
			var vm = this;

			vm.setSendButtonStatus("wait");
			let dateNow = moment();

			loginApi
				.checkActiveKey({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					let waitTime = 1000 - moment().diff(dateNow, "milliseconds");
					if (waitTime < 0) waitTime = 0;

					setTimeout(() => {
						if (res.Success) {
							if (res.Action == "Verified") {
								vm.parentVM.setPage("finish");
							}
							if (res.Action == "Unverified") {
								msgCtrl.showNotifyDefault(
									msgCtrl.iconList.alert,
									"驗證失敗",
									"檢查輸入的驗證碼是否與 Email 中收到的驗證碼一致，並請再試一次",
									msgCtrl.notifyDelay.default
								);
								vm.setSendButtonStatus("normal");
								return;
							}
							if (res.Action == "Timeout") {
								msgCtrl.showNotifyDefault(
									msgCtrl.iconList.alert,
									"驗證已過期",
									"您的驗證碼已經逾期，重新進行登入",
									msgCtrl.notifyDelay.default
								);
								vm.parentVM.setInital();
								return;
							}
						} else {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證失敗",
								"驗證驗證碼的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("normal");
							return;
						}
					}, waitTime);
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼檢查時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setSendButtonStatus("normal");
				})
				.finally(() => {});
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-verify-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-verify-page-wrapper {
		padding-bottom: 6rem;
	}
}
.views-verify-userInfo {
	position: relative;
	width: 240px;
	font-weight: bold;
	display: inline;
	padding: 0.35rem 0.75rem;
	background: #fff;
	color: #000;
	border: solid 3px var(--color);
	border-radius: 36px;
}
.views-verify-userInfo::before {
	content: "";
	border-radius: 36px;
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		-45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	animation: shadow 20s linear infinite;
	background-size: 400%;
	z-index: -1;
	opacity: 0;
	transition: 0.5s;
}
.views-verify-userInfo::after {
	content: "";
	border-radius: 36px;
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	background-size: 400%;
	z-index: -1;
	animation: shadow 20s linear infinite;
	filter: blur(8px);
	opacity: 0;
	transition: 0.5s;
}
.views-verify-userInfo:hover {
	border: solid 0 rgba(100, 100, 255, 0);
	transition: 0s;
}
.views-verify-userInfo:hover::before {
	opacity: 1;
	transition: 0.35s;
}
.views-verify-userInfo:hover::after {
	opacity: 0.5;
	transition: 0.35s;
}
.views-verify-userInfo svg {
	margin-right: 0.35rem;
	color: var(--color);
}
</style>
