<template>
	<div class="views-ui-dynamic-wrapper">
		<div class="views-ui-dynamic-bg"></div>
		<waveBgVue />
		<div class="views-ui-dynamic-border"></div>
	</div>
</template>

<script>
import waveBgVue from "@/components/global/waveBg.vue";
export default {
	name: "view-ui-dynamic",
	props: [],
	data() {
		return {};
	},
	components: {
		waveBgVue,
	},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-dynamic-wrapper {
	position: absolute;
	top: 0.5rem;
	left: 260px;
	right: 0.5rem;
	bottom: 0.5rem;
	border-radius: 16px;
	transition: 0.35s;
}
@media (max-width: 992px) {
	.views-ui-dynamic-wrapper {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.views-ui-dynamic-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 16px;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
	.views-ui-dynamic-bg {
		opacity: 1;
		box-shadow: none;
	}
}
.views-ui-dynamic-border {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 16px;
	pointer-events: none;
	border: solid 1px rgba(200, 200, 200, 0.55);
}
</style>
