import client from "@/api/client";

export default {
	checkEmail: (data) => {
		return client({
			url: "api/Login/CheckEmail",
			method: "get",
			params: data,
		});
	},
	loginUser: (data) => {
		return client({
			url: "api/Login/LoginUser",
			method: "post",
			data,
		});
	},
	checkActiveKey: (data) => {
		return client({
			url: "api/Login/CheckActiveKey",
			method: "post",
			data,
		});
	},
	setLoginData: (data) => {
		return client({
			url: "api/Login/SetLoginData",
			method: "post",
			data,
		});
	},
};
