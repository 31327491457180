<template>
	<div class="component-appicontrans-wrapper" :class="status">
		<appIconVue
			:size="size"
			:radius="radius"
			:trans="trans"
			:style="{
				'--opacity': opacity,
				transition: (trans ?? 0) + 's',
				'--translateX': left + 'px',
				'--translateY': top + 'px',
			}"
			class="component-appicontrans-icon"
			:class="fadeStatus"
		/>
	</div>
</template>

<script>
import _ from "lodash";
import appIconVue from "./appIcon.vue";
export default {
	name: "appIcon",
	props: [],
	data() {
		return {
			status: "",
			fadeStatus: "",
			size: 128,
			radius: 32,
			top: 0,
			left: 0,
			opacity: 1,
			trans: 0,
			targetAppIcon: null,
			doReSetTargetEvent: null,
			timerAutoSet: null,
		};
	},
	computed: {},
	components: {
		appIconVue,
	},
	watch: {},
	methods: {
		setTarget(target, trans, status) {
			var vm = this;
			if (status == "hide") vm.status = status;
			if (target == null) return;
			vm.status = status;
			vm.trans = trans;
			let rect = target.$el.getBoundingClientRect();
			vm.left = rect.x;
			vm.top = rect.y;
			vm.size = target.imgSize;
			vm.radius = target.imgRadius;
			vm.targetAppIcon = target;
		},
		setFadeToTarget(target, status) {
			var vm = this;
			if (status == "show") {
				vm.opacity = 0;
				vm.setTarget(target, 0, status);
				setTimeout(() => {
					vm.opacity = 1;
					vm.fadeStatus = "app-icon-fade-in";
				}, 10);
				setTimeout(() => {
					if (vm.fadeStatus == "app-icon-fade-in") {
						vm.opacity = 1;
						vm.fadeStatus = "";
					}
				}, 1000);
			}
			if (status == "hide") {
				vm.opacity = 1;
				setTimeout(() => {
					vm.fadeStatus = "app-icon-fade-out";
				}, 10);
				setTimeout(() => {
					if (vm.fadeStatus == "app-icon-fade-out") {
						console.log("make hide");
						vm.opacity = 0;
						vm.fadeStatus = "";
						vm.setTarget(target, 0, status);
					}
				}, 1000);
			}
		},
		resize() {
			var vm = this;
			if (vm.doReSetTargetEvent != null) vm.doReSetTargetEvent();
		},
		doReSetTarget() {
			var vm = this;
			if (vm.status !== "show") return;
			if (vm.targetAppIcon) vm.setTarget(vm.targetAppIcon, 0.35, vm.status);
		},
	},
	created() {
		var vm = this;
		vm.doReSetTargetEvent = _.debounce(vm.doReSetTarget, 450);
		window.addEventListener("resize", vm.resize);
	},
	destroyed() {
		var vm = this;
		window.removeEventListener("resize", vm.resize);
		if (vm.timerAutoSet != null) clearInterval(vm.timerAutoSet);
	},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-appicontrans-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	display: none;
}
.component-appicontrans-wrapper.show {
	display: block;
}
.component-appicontrans-wrapper.hide {
	display: block;
}
.component-appicontrans-icon {
	opacity: var(--opacity);
	transform: translate(var(--translateX), var(--translateY)) scale(1);
}
.app-icon-fade-in {
	opacity: 1;
	animation: app-icon-fade-in 0.65s ease-in-out forwards;
}
@keyframes app-icon-fade-in {
	0% {
		transform: translate(var(--translateX), var(--translateY)) scale(0.75);
		filter: blur(12px);
		opacity: 0;
	}
	75% {
		transform: translate(var(--translateX), var(--translateY)) scale(1.1);
	}
	100% {
		transform: translate(var(--translateX), var(--translateY)) scale(1);
		filter: blur(0);
		opacity: 1;
	}
}
.app-icon-fade-out {
	opacity: 0;
	animation: app-icon-fade-out 0.35s ease-in-out forwards;
}
@keyframes app-icon-fade-out {
	0% {
		transform: translate(var(--translateX), var(--translateY)) scale(1);
		filter: blur(0);
		opacity: 1;
	}
	25% {
		transform: translate(var(--translateX), var(--translateY)) scale(1.1);
	}
	100% {
		transform: translate(var(--translateX), var(--translateY)) scale(0.75);
		filter: blur(12px);
		opacity: 0;
	}
}
</style>
