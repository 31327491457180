export default {
	namespaced: true,
	state: {
		msgBoxList: [],
		notifyList: [],
	},
	getters: {},
	mutations: {
		setMsgBox(state, v) {
			state.msgBoxList.push(v);
		},
		removeMsgBox(state, v) {
			state.msgBoxList.splice(state.msgBoxList.indexOf(v), 1);
		},
		clearMsgBox(state) {
			state.msgBoxList = [];
		},

		setNotify(state, v) {
			state.notifyList.push(v);
		},
		removeNotify(state, v) {
			state.notifyList.splice(state.notifyList.indexOf(v), 1);
		},
		clearNotify(state) {
			state.notifyList = [];
		},
	},
	actions: {
		setMsgBox(context, input) {
			context.commit("setMsgBox", input);
		},
		removeMsgBox(context, input) {
			context.commit("removeMsgBox", input);
		},
		clearMsgBox(context) {
			context.commit("clearMsgBox");
		},

		setNotify(context, input) {
			context.commit("setNotify", input);
		},
		removeNotify(context, input) {
			context.commit("removeNotify", input);
		},
		clearNotify(context) {
			context.commit("clearNotify");
		},
	},
};
