<template >
	<div class="page-wrapper">
		<div
			class="page-container container"
			:style="{
				maxWidth: getMaxWidth,
			}"
		>
			<div
				class="page-container-landscape"
				ref="container"
				:style="{
					transform:
						'translate(' +
						pageStatus.left +
						'px' +
						',' +
						pageStatus.top +
						'px' +
						')',
				}"
			>
				<slot>
					<div class="row h-100">
						<div class="col-12">未插入頁面</div>
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
export default {
	name: "pageUI",
	props: ["maxWidth", "pageData", "pageItem"],
	data() {
		return {
			doResetPageMoveEvent: null,
			pageStatus: {
				top: 0,
				left: 0,
			},
		};
	},
	computed: {
		getMaxWidth: {
			get() {
				var vm = this;
				let result = "auto";
				if (vm.maxWidth == null) return result;
				result = vm.maxWidth + "px";
				return result;
			},
		},
	},
	watch: {
		pageItem() {
			var vm = this;
			setTimeout(() => {
				vm.setPageMove();
			}, 50);
		},
	},
	methods: {
		setPageMove() {
			var vm = this;
			let container = vm.$refs.container?.getBoundingClientRect() ?? null;
			let target = vm.pageItem?.$el.getBoundingClientRect() ?? null;
			if (container == null || target == null) return;
			let left = container.left - target.left;
			vm.pageStatus.left = left;
		},
		resize() {
			var vm = this;
			if (vm.doResetPageMoveEvent != null) vm.doResetPageMoveEvent();
		},
	},
	created() {
		var vm = this;
		vm.doResetPageMoveEvent = _.debounce(vm.setPageMove, 450);
		window.addEventListener("resize", vm.resize);
	},
	destroyed() {
		var vm = this;
		window.removeEventListener("resize", vm.resize);
	},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
