import client from "@/api/client";

export default {
	getUserEntity: (data) => {
		return client({
			url: "api/Info/GetUserEntity",
			method: "post",
			params: data,
		});
	},
};
