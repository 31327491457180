<template >
	<div class="views-verify-page-wrapper">
		<div class="row">
			<div class="col-12 mb-2">
				<button class="btn-back" @click="onBackBtnClick">
					<i class="fa-solid fa-arrow-left"></i>
				</button>
			</div>
			<div class="col-12 text-center mb-2">
				<i class="fa-solid fa-envelope-circle-check fa-6x"></i>
			</div>
			<div class="col-12 text-center mt-2 mb-2">
				<h3><strong>驗證 Email</strong></h3>
			</div>
			<div class="col-12 mt-2 mb-2">
				<blockquote>
					我們將使用下列 Email 作為您的登入帳號，請確認此 Email
					無誤之後，點擊「取得驗證碼」按鈕，我們將發送一封包含本次驗證碼的信件到您的信箱。
				</blockquote>
			</div>
			<div class="col-12 mt-2 mb-2">
				<div class="row">
					<div class="col-3 d-none d-md-block"></div>
					<div class="col-12 col-md-6">
						<input class="tb-normal full-width read-only" v-model="email" />
					</div>
					<div class="col-12 col-md-3 text-end text-md-start mt-3 mt-md-0">
						<button
							class="btn-normal"
							:class="[sendButtonStatus.color]"
							:disabled="sendButtonStatus.disabled"
							@click="onSendBtnClick"
						>
							<transition name="text-fade" mode="out-in">
								<span :key="sendButtonStatus.text">
									<i
										v-show="sendButtonStatus.icon == 'normal'"
										class="fa-solid fa-paper-plane"
									></i>
									<i
										v-show="sendButtonStatus.icon == 'wait'"
										class="fa-solid fa-rotate fa-spin"
									></i>
									<i
										v-show="sendButtonStatus.icon == 'resend'"
										class="fa-solid fa-clock fa-fade"
									></i>
									{{ sendButtonStatus.text }}
								</span>
							</transition>
						</button>
					</div>
				</div>
			</div>
			<div
				class="col-12 mb-2 mt-2 verify-input"
				:class="{
					'anime-height-in': verifyStatus == 'show',
					show: verifyStatus == 'show',
					'anime-height-out': verifyStatus == 'hide',
				}"
			>
				<blockquote>
					我們發送了一封包含本次註冊驗證碼的信件到您的信箱中，請將信件中的驗證碼填寫到下方，填寫完成之後請點擊「進行驗證」讓我們確認此信箱為您本人使用。
				</blockquote>
			</div>
			<div
				class="col-12 mt-2 mb-2 text-center verify-input"
				:class="{
					'anime-height-in': verifyStatus == 'show',
					show: verifyStatus == 'show',
					'anime-height-out': verifyStatus == 'hide',
				}"
			>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key1"
					data-key="1"
					v-model="activeKey1"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key2"
					data-key="2"
					v-model="activeKey2"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key3"
					data-key="3"
					v-model="activeKey3"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key4"
					data-key="4"
					v-model="activeKey4"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key5"
					data-key="5"
					v-model="activeKey5"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
				<input
					class="tb-verify"
					maxlength="1"
					ref="key6"
					data-key="6"
					v-model="activeKey6"
					@paste="onPaste"
					@keydown="onActiveKeyDown"
					@keyup="onActiveKeyUp"
					@focus="$event.target.select()"
				/>
			</div>
			<div
				class="col-12 text-center mt-4 verify-input"
				:class="{
					'anime-height-in': verifyStatus == 'show',
					show: verifyStatus == 'show',
					'anime-height-out': verifyStatus == 'hide',
				}"
			>
				<button
					class="btn-normal"
					:class="[verifyButtonStatus.color]"
					:disabled="verifyButtonStatus.disabled"
					@click="onVerifyBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="verifyButtonStatus.text">
							<i
								v-show="verifyButtonStatus.icon == 'normal'"
								class="fa-solid fa-arrow-right"
							></i>
							<i
								v-show="verifyButtonStatus.icon == 'verify'"
								class="fa-solid fa-check-double"
							></i>
							<i
								v-show="verifyButtonStatus.icon == 'wait'"
								class="fa-solid fa-arrows-rotate fa-spin"
							></i>
							{{ verifyButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import registerApi from "@/api/register";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "view-ui-register-verify",
	props: [],
	data() {
		return {
			verifyStatus: "",
			sendButtonStatus: {
				color: "secondary",
				text: "取得驗證碼",
				icon: "normal",
				disabled: false,
				status: "",
			},
			sendButtonList: {
				normal: {
					color: "secondary",
					text: "取得驗證碼",
					icon: "normal",
					disabled: false,
					status: "",
				},
				wait: {
					color: "",
					text: "正在發送",
					icon: "wait",
					disabled: true,
					status: "",
				},
				resend: {
					color: "",
					text: "再次發送",
					icon: "resend",
					disabled: true,
					status: "",
				},
			},
			verifyButtonStatus: {
				color: "primary",
				text: "進行驗證",
				icon: "normal",
				disabled: true,
				status: "",
			},
			verifyButtonList: {
				normal: {
					color: "primary",
					text: "進行驗證",
					icon: "normal",
					disabled: true,
				},
				verify: {
					color: "primary",
					text: "進行驗證",
					icon: "verify",
					disabled: false,
				},
				wait: {
					color: "",
					text: "正在驗證",
					icon: "wait",
					disabled: true,
				},
			},
		};
	},
	components: {},
	computed: {
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
		},
		verifyUID: {
			get() {
				var vm = this;
				return vm.parentVM.model.verifyUID;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.verifyUID = value;
			},
		},
		activeKey1: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key1;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key1 = value.toUpperCase();
			},
		},
		activeKey2: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key2;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key2 = value.toUpperCase();
			},
		},
		activeKey3: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key3;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key3 = value.toUpperCase();
			},
		},
		activeKey4: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key4;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key4 = value.toUpperCase();
			},
		},
		activeKey5: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key5;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key5 = value.toUpperCase();
			},
		},
		activeKey6: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key6;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.activeKeyList.key6 = value.toUpperCase();
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.activeKey1 +
					vm.activeKey2 +
					vm.activeKey3 +
					vm.activeKey4 +
					vm.activeKey5 +
					vm.activeKey6
				);
			},
		},
		isCanVerify: {
			get() {
				var vm = this;
				let result = true;
				if (vm.activeKey1 == null || vm.activeKey1 == "") result = false;
				if (vm.activeKey2 == null || vm.activeKey2 == "") result = false;
				if (vm.activeKey3 == null || vm.activeKey3 == "") result = false;
				if (vm.activeKey4 == null || vm.activeKey4 == "") result = false;
				if (vm.activeKey5 == null || vm.activeKey5 == "") result = false;
				if (vm.activeKey6 == null || vm.activeKey6 == "") result = false;

				return result;
			},
		},
	},
	watch: {
		isCanVerify(newValue) {
			var vm = this;
			if (newValue) vm.setVerifyButtonStatus("verify");
			else vm.setVerifyButtonStatus("normal");
		},
	},
	methods: {
		setInital() {
			var vm = this;
			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";
			vm.verifyStatus = "";
			vm.setSendButtonStatus("normal");
		},
		setSendButtonStatus(name) {
			var vm = this;
			vm.sendButtonStatus = JSON.parse(JSON.stringify(vm.sendButtonList[name]));
		},
		setVerifyButtonStatus(name) {
			var vm = this;
			vm.verifyButtonStatus = JSON.parse(
				JSON.stringify(vm.verifyButtonList[name])
			);
		},
		onPaste(e) {
			var vm = this;
			let input = null;

			let clipboardData = null;
			try {
				clipboardData = e.clipboardData;
			} catch {
				clipboardData = null;
			}

			if (!clipboardData) clipboardData = e.originalEvent.clipboardData;
			input = clipboardData.getData("Text");
			if (input == null) return;

			input = input.toUpperCase().trim();
			if (input.length != 6) return;
			[...input].forEach((x, i) => {
				vm["activeKey" + (i + 1)] = x;
			});
			vm.$refs["key6"].focus();
		},
		onActiveKeyDown(e) {
			var vm = this;
			if (e.key === "Backspace" || e.key === "Delete") {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value != "") return;
				if (keyNow == 1) return;
				let backName = "key" + (keyNow - 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onActiveKeyUp(e) {
			var vm = this;
			if (e.key === "Enter" && vm.isCanVerify) {
				vm.callCheckActiveKey();
				return;
			}
			if (e.key === "Backspace" || e.key === "Delete") {
				return;
			} else {
				let keyNow = parseInt(e.target.getAttribute("data-key"));
				if (e.target.value == "") return;
				if (keyNow == 6) return;
				let backName = "key" + (keyNow + 1).toString();
				vm.$refs[backName].focus();
			}
		},
		onSendBtnClick() {
			var vm = this;
			vm.callSendVerifyEmail();
		},
		onVerifyBtnClick() {
			var vm = this;
			vm.callCheckActiveKey();
		},
		onBackBtnClick() {
			var vm = this;
			vm.parentVM.setPage("license");
		},
		callSendVerifyEmail() {
			var vm = this;

			vm.activeKey1 =
				vm.activeKey2 =
				vm.activeKey3 =
				vm.activeKey4 =
				vm.activeKey5 =
				vm.activeKey6 =
					"";

			vm.setSendButtonStatus("wait");
			registerApi
				.verifyEmail({
					Email: vm.email,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "NotEmail") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"檢測錯誤",
								"目前輸入的 Email 帳號不符合格式，如果錯誤持續發生，請重新整理並再次重試",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("normal");
							return;
						}
						if (res.Action == "Registed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"檢測錯誤",
								"目前輸入的 Email 已經註冊過了，請直接進行登入",
								msgCtrl.notifyDelay.default
							);
							vm.parentVM.switchToLogin();
							return;
						}
						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"發送失敗",
								"發送驗證碼的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("normal");
							return;
						}
						if (res.Action == "Send") {
							vm.setSendButtonStatus("resend");
							vm.verifyStatus = "show";
							vm.verifyUID = res.Data[0];
							setTimeout(() => {
								vm.$refs.key1.focus();
							}, 450);
							setTimeout(() => {
								vm.setSendButtonStatus("normal");
							}, 60 * 1000);
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"發送失敗",
							"發送驗證碼的過程發生錯誤，伺服器返回一個邏輯錯誤",
							msgCtrl.notifyDelay.default
						);
						vm.setSendButtonStatus("normal");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼發送時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setSendButtonStatus("normal");
				})
				.finally(() => {});
		},
		callCheckActiveKey() {
			var vm = this;

			vm.setVerifyButtonStatus("wait");
			registerApi
				.checkActiveKey({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Verified") {
							vm.setVerifyButtonStatus("verify");
							vm.parentVM.setPage("setting");
							return;
						}
						if (res.Action == "Unverified") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證失敗",
								"請檢查輸入的驗證碼是否與 Email 中收到的驗證碼一致，並請再試一次",
								msgCtrl.notifyDelay.default
							);
							vm.setVerifyButtonStatus("verify");
							return;
						}
						if (res.Action == "Timeout") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證碼已過期",
								"您的驗證碼已經逾期，請點擊「發送驗證碼」重新取得新的驗證碼",
								msgCtrl.notifyDelay.default
							);
							vm.setVerifyButtonStatus("normal");
							vm.setSendButtonStatus("normal");
							return;
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"發送失敗",
							"發送驗證碼的過程發生錯誤，伺服器返回一個邏輯錯誤",
							msgCtrl.notifyDelay.default
						);
						vm.setVerifyButtonStatus("verify");
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行驗證碼檢查時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.setVerifyButtonStatus("verify");
				})
				.finally(() => {});
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-verify-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-verify-page-wrapper {
		padding-bottom: 3rem;
	}
}
@media (max-height: 768px) and (max-width: 576px) {
	.views-verify-page-wrapper {
		align-items: flex-start;
		padding-bottom: 2rem;
		overflow: hidden;
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.verify-input {
	opacity: 0;
	pointer-events: none;
	max-height: 0;
}
.verify-input.show {
	pointer-events: all;
}
</style>
