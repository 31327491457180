<template >
	<div class="menu-item-wrapper">
		<span
			><i v-if="itemData.itemIcon" :class="[itemData.itemIcon]"></i
			>{{ itemData.itemName }}</span
		>
	</div>
</template>

<script>
export default {
	name: "menuItem",
	props: ["itemData", "clickEvent"],
	data() {
		return {};
	},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
