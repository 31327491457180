export default {
	getUserEntity: getUserEntity,
	setUserEntity: setUserEntity,
	setUserEntityClear: setUserEntityClear,
	getUserToken: getUserToken,
	setUserToken: setUserToken,
	setUserTokenClear: setUserTokenClear,
};

function getUserEntity() {
	return localStorage.getItem("userEntity")
		? JSON.parse(localStorage.getItem("userEntity")).UserEntity
		: null;
}

function setUserEntity(userEntity) {
	let data = {
		UserEntity: userEntity,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userEntity", JSON.stringify(data));
}

function setUserEntityClear() {
	localStorage.removeItem("userEntity");
}

function getUserToken() {
	return localStorage.getItem("userToken")
		? JSON.parse(localStorage.getItem("userToken"))
		: { UserToken: null, CreateTime: null };
}

function setUserToken(userToken) {
	let data = {
		UserToken: userToken,
		CreateTime: new Date().now,
	};
	localStorage.setItem("userToken", JSON.stringify(data));
}

function setUserTokenClear() {
	localStorage.removeItem("userToken");
}
