<template >
	<div class="views-finish-page-wrapper">
		<div class="row">
			<div class="col-12 text-center mt-4 mb-4">
				<div
					class="views-finish-userInfo"
					:class="[wrapperStatus]"
					:style="{
						'--color':
							'rgb(' +
							nameToColor[0] +
							',' +
							nameToColor[1] +
							',' +
							nameToColor[2] +
							')',
					}"
				>
					<div class="views-finish-userInfo-bg"></div>
					<div>
						<div class="views-finish-userInfo-base">
							<div class="views-finish-userInfo-icon">
								<i class="fa-solid fa-address-card fa-2x"></i>
							</div>
							<div class="views-finish-userInfo-text">
								<span>{{ displayName }}</span
								><br />
								<span>{{ email }}</span>
							</div>
						</div>
						<div class="views-finish-userInfo-more">
							<i class="fa-solid fa-arrow-right"></i> 正在登入，請稍後
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import globalUtils from "@/utils/globalUtils";
import loginApi from "@/api/login";
import msgCtrl from "@/utils/msgCtrl";
import userCtrl from "@/utils/userCtrl";
import router from "@/router";
export default {
	name: "view-ui-login-finish",
	props: [],
	data() {
		return {
			wrapperStatus: "",
		};
	},
	components: {},
	computed: {
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.parentVM.model.displayName;
			},
		},
		verifyUID: {
			get() {
				var vm = this;
				return vm.parentVM.model.verifyUID;
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.parentVM.model.activeKeyList.key1 +
					vm.parentVM.model.activeKeyList.key2 +
					vm.parentVM.model.activeKeyList.key3 +
					vm.parentVM.model.activeKeyList.key4 +
					vm.parentVM.model.activeKeyList.key5 +
					vm.parentVM.model.activeKeyList.key6
				);
			},
		},
		nameToColor: {
			get() {
				var vm = this;
				return globalUtils.getNameToColor(vm.displayName);
			},
		},
	},
	watch: {},
	methods: {
		setInital() {
			var vm = this;
			vm.wrapperStatus = "show";
			vm.callSetLoginData();
		},
		setUninital() {
			var vm = this;
			vm.wrapperStatus = "";
		},
		callSetLoginData() {
			var vm = this;

			loginApi
				.setLoginData({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
				})
				.then((res) => {
					if (res.Success) {
						if (res.Action == "Failed") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"登入失敗",
								"進行登入的過程發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
								msgCtrl.notifyDelay.default
							);
							vm.parentVM.setInital();
							return;
						}
						if (res.Action == "Timeout") {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"驗證已過期",
								"您的驗證資訊已經逾期，重新進行登入",
								msgCtrl.notifyDelay.default
							);
							vm.parentVM.setInital();
							return;
						}
						if (res.Action == "Success") {
							userCtrl.setUserToken(res.Data[0]);
							setTimeout(() => {
								vm.wrapperStatus = "hide";
								setTimeout(() => {
									vm.parentVM.hideLogin();
									setTimeout(() => {
										router.push({ name: "startup", replace: true });
									}, 500);
								}, 500);
							}, 3000);
						}
					} else {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.alert,
							"登入失敗",
							"使用驗證資訊登入時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
							msgCtrl.notifyDelay.default
						);
						vm.parentVM.setInital();
						return;
					}
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線使用驗證資訊登入時發生錯誤，請稍後再重試，如果錯誤持續發生，請通知我們",
						msgCtrl.notifyDelay.default
					);
					vm.parentVM.setInital();
				})
				.finally(() => {});
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-finish-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-finish-page-wrapper {
		padding-bottom: 6rem;
	}
}
.views-finish-userInfo {
	position: relative;
	padding: 0.5rem 1.5rem 0.5rem 0;
	color: #000;
	transition: 0.5s;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	opacity: 0;
}
.views-finish-userInfo.show {
	opacity: 0;
	animation: views-finish-userInfo-show 0.75s 0.5s forwards;
}
@keyframes views-finish-userInfo-show {
	0% {
		transform: scale(0.95);
		filter: blur(8px);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		filter: blur(0);
		opacity: 1;
	}
}
.views-finish-userInfo.hide {
	opacity: 0;
	animation: views-finish-userInfo-hide 0.5s forwards;
}
@keyframes views-finish-userInfo-hide {
	0% {
		transform: scale(1);
		filter: blur(0);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		filter: blur(8px);
		opacity: 0;
	}
}

.views-finish-userInfo svg {
	margin-right: 0.35rem;
	color: var(--color);
}
.views-finish-userInfo-icon {
	position: relative;
	display: inline-block;
	padding: 0.5rem 0.5rem 0.5rem 1rem;
	margin-right: 1rem;
	border-right: solid 1px rgba(200, 200, 200, 0.55);
}
.views-finish-userInfo-text {
	position: relative;
	display: inline-block;
	align-items: center;
	text-align: start;
	min-width: 180px;
}
.views-finish-userInfo-base {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.views-finish-userInfo-more {
	position: relative;
	font-size: 8pt;
	margin-top: 0.5rem;
	margin-right: -1.5rem;
	border-top: solid 1px rgba(200, 200, 200, 0.55);
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	text-align: end;
}
.views-finish-userInfo-more svg {
	color: #000;
}

.views-finish-userInfo-text span:first-child {
	font-weight: bold;
}
.views-finish-userInfo-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	--smooth-corners: 18;
	mask-image: paint(smooth-corners);
	-webkit-mask-image: paint(smooth-corners);
}
.views-finish-userInfo::before {
	content: "";
	--smooth-corners: 16;
	mask-image: paint(smooth-corners);
	-webkit-mask-image: paint(smooth-corners);
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		-45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	animation: shadow 20s linear infinite;
	background-size: 400%;
	z-index: -1;
	opacity: 1;
	transition: 0.5s;
}
.views-finish-userInfo::after {
	content: "";
	border-radius: 36px;
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	background: linear-gradient(
		45deg,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000,
		#fb0094,
		#0000ff,
		#00ff00,
		#ffff00,
		#ff0000
	);
	background-size: 400%;
	z-index: -1;
	animation: shadow 20s linear infinite;
	filter: blur(8px);
	opacity: 0.5;
	transition: 0.5s;
}
</style>
