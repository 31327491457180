<template >
	<div
		class="layer-wrapper"
		:class="[
			{
				'single-layer': isSingleLayer,
				'multi-layer': isMultiLayer,
				'top-layer': isTopLayer,
				'hide-layer': isHideLayer,
				'close-layer': isCloseLayer,
				'layer-modal': layerData.layerType == 'modal',
			},
		]"
	>
		<div
			class="layer-content container"
			:class="[
				{
					'layer-modal': layerData.layerType == 'modal',
				},
			]"
		>
			<slot>
				<div class="row h-100">
					<div class="col-12">未生成內容</div>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "layerUI",
	props: ["layerData", "layerList"],
	data() {
		return {};
	},
	computed: {
		showLayerList: {
			get() {
				var vm = this;
				return vm.layerList.filter((x) => {
					return x.status == "normal";
				});
			},
		},
		isCloseLayer: {
			get() {
				var vm = this;
				return vm.layerData.status == "close";
			},
		},
		isSingleLayer: {
			get() {
				var vm = this;
				if (vm.showLayerList.length == 1) return true;
				return false;
			},
		},
		isMultiLayer: {
			get() {
				var vm = this;
				let result = false;
				if (vm.showLayerList.length > 1) result = true;
				return result;
			},
		},
		isTopLayer: {
			get() {
				var vm = this;
				let result = false;
				if (
					vm.showLayerList.indexOf(vm.layerData) ==
					vm.showLayerList.length - 1
				)
					result = true;
				return result;
			},
		},
		isHideLayer: {
			get() {
				var vm = this;
				let result = false;
				if (
					vm.showLayerList.length - 1 - vm.showLayerList.indexOf(vm.layerData) >
					1
				)
					result = true;
				return result;
			},
		},
	},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
