var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-appicon-wrapper"},[_c('div',{staticClass:"component-appicon-icon-wrapper",style:({
			width: (_vm.size ?? 128) + 'px',
			height: (_vm.size ?? 128) + 'px',
			transition: (_vm.trans ?? 0) + 's',
		})},[_c('div',{staticClass:"rainbow-shadow",style:({
				'--borderRadius': (_vm.radius ?? 8) + 'px',
			})}),_c('div',{staticClass:"rainbow-border",style:({
				'--borderRadius': (_vm.radius ?? 8) + 'px',
			})}),_c('div',{staticClass:"component-appicon-icon-bg",style:({
				'--borderRadius': (_vm.radius ?? 8) + 'px',
			})}),_c('div',{staticClass:"component-appicon-icon-mask",style:({
				'--borderRadius': (_vm.radius ?? 8) + 'px',
			})}),_c('div',{staticClass:"component-appicon-icon-img-wrapper"},[_c('img',{style:({
					width: (_vm.imgSize ?? 128) + 'px',
					height: (_vm.imgSize ?? 128) + 'px',
				}),attrs:{"src":require("@/assets/icon.png")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }