<template >
	<div class="views-license-page-wrapper">
		<div class="row">
			<div class="col-12 mt-4 mb-2">
				<button class="btn-back" @click="onBackBtnClick">
					<i class="fa-solid fa-arrow-left"></i>
				</button>
			</div>
			<div class="col-12 text-center mb-2">
				<i class="fa-solid fa-handshake-simple fa-6x"></i>
			</div>
			<div class="col-12 text-center mt-2 mb-2">
				<h3><strong>用戶協議</strong></h3>
			</div>
			<div class="col-12 mt-2 mb-2">
				<div class="blockquote-license">
					<blockquote>
						本應用僅供個人記帳、個人財務進出管理使用，此應用輸出之圖表、報表的資料準確性無法提供擔保。
					</blockquote>
					<blockquote>
						您的 Email 即是應用辨識您的身份的唯一途徑，如果您遺失您的
						Email，您將永遠無法重新取得您的資料。
					</blockquote>
					<blockquote>
						本應用使用 OTP 隨機密碼進行登入，因此請務必確保您的 Email
						能正常使用。
					</blockquote>
					<blockquote>
						為了維持應用運作，我們將會使用廣告服務商，並在界面上顯示廣告，如果您不希望在此應用上看到廣告，您可以訂閱去廣告功能。
					</blockquote>
					<blockquote>
						開發團隊不對使用本應用期間所造成的任何直接或間接損失負責，本應用僅在用戶知悉這點的情況下提供服務。
					</blockquote>
				</div>
			</div>
			<div class="col-12 mt-4 text-center">
				<button
					class="btn-normal"
					:class="[sendButtonStatus.color]"
					:disabled="sendButtonStatus.disabled"
					@click="onSendBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="sendButtonStatus.text">
							<i
								v-show="sendButtonStatus.icon == 'normal'"
								class="fa-solid fa-check"
							></i>
							{{ sendButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "view-ui-register-license",
	props: [],
	data() {
		return {
			sendButtonStatus: {
				color: "success",
				text: "我已閱讀並同意上述協議",
				icon: "normal",
				disabled: false,
				status: "",
			},
		};
	},
	components: {},
	computed: {
		appIcon: {
			get() {
				var vm = this;
				return vm.$refs.appIcon;
			},
		},
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
	},
	watch: {},
	methods: {
		setInital() {},
		onSendBtnClick() {
			var vm = this;
			vm.parentVM.setPage("verify");
		},
		onBackBtnClick() {
			var vm = this;
			vm.parentVM.setPage("welcome");
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-license-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-license-page-wrapper {
		padding-bottom: 3rem;
		padding-top: 0;
	}
}
@media (max-height: 768px) and (max-width: 576px) {
	.views-verify-page-wrapper {
		align-items: flex-start;
		padding-bottom: 2rem;
		overflow: hidden;
		overflow-x: hidden;
		overflow-y: auto;
	}
}
</style>
