var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-finish-page-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center mt-4 mb-4"},[_c('div',{staticClass:"views-finish-userInfo",class:[_vm.wrapperStatus],style:({
					'--color':
						'rgb(' +
						_vm.nameToColor[0] +
						',' +
						_vm.nameToColor[1] +
						',' +
						_vm.nameToColor[2] +
						')',
				})},[_c('div',{staticClass:"views-finish-userInfo-bg"}),_c('div',[_c('div',{staticClass:"views-finish-userInfo-base"},[_vm._m(0),_c('div',{staticClass:"views-finish-userInfo-text"},[_c('span',[_vm._v(_vm._s(_vm.displayName))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.email))])])]),_vm._m(1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-finish-userInfo-icon"},[_c('i',{staticClass:"fa-solid fa-address-card fa-2x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-finish-userInfo-more"},[_c('i',{staticClass:"fa-solid fa-arrow-right"}),_vm._v(" 正在登入，請稍後 ")])
}]

export { render, staticRenderFns }