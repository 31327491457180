var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-ui-userinfo-wrapper",class:[_vm.menuStatus]},[_c('div',{staticClass:"views-ui-userinfo-dim",class:{ show: _vm.userInfoStatus == 'view-large' },on:{"click":_vm.onChangeBtnClick}}),_c('div',{staticClass:"views-ui-userinfo",class:[_vm.userInfoStatus],style:({
			'--color':
				'rgb(' +
				_vm.nameToColor[0] +
				',' +
				_vm.nameToColor[1] +
				',' +
				_vm.nameToColor[2] +
				')',
		})},[_c('div',{staticClass:"views-ui-userinfo-bg"}),_c('div',{staticClass:"views-ui-userinfo-content"},[_c('div',{staticClass:"views-ui-userinfo-base"},[_c('div',{staticClass:"views-ui-userinfo-icon"},[_vm._v(" "+_vm._s(_vm.iconChar)+" ")]),_c('div',{staticClass:"views-ui-userinfo-text text-truncate"},[_c('span',[_vm._v(_vm._s(_vm.displayName))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.email))])])]),_vm._m(0)]),_c('button',{staticClass:"view-ui-userinfo-change-btn",on:{"click":_vm.onChangeBtnClick}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-ui-userinfo-more"},[_c('button',{staticClass:"view-ui-userinfo-item"},[_c('i',{staticClass:"fa-solid fa-gear"}),_vm._v(" 選項設定 ")]),_c('button',{staticClass:"view-ui-userinfo-item"},[_c('i',{staticClass:"fa-solid fa-user"}),_vm._v(" 帳號設定 ")]),_c('button',{staticClass:"view-ui-userinfo-item"},[_c('i',{staticClass:"fa-solid fa-right-from-bracket"}),_vm._v(" 登出帳號 ")])])
}]

export { render, staticRenderFns }