var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-finish-page-wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-2 text-center"},[_c('appIconVue',{ref:"appIcon",staticStyle:{"opacity":"0"},attrs:{"size":"96","radius":"24"}})],1),_c('div',{staticClass:"col-12 mt-4 mb-4 text-center"},[_c('div',{staticClass:"views-finish-userInfo",style:({
					'--color':
						'rgb(' +
						_vm.nameToColor[0] +
						',' +
						_vm.nameToColor[1] +
						',' +
						_vm.nameToColor[2] +
						')',
				})},[_c('i',{staticClass:"fa-solid fa-circle-user"}),_vm._v(_vm._s(_vm.displayName ?? "未知顯示名稱")+" ")])]),_c('div',{staticClass:"col-12 mt-2 mb-2"},[_c('blockquote',{directives:[{name:"show",rawName:"v-show",value:(_vm.finishStatus == 'Success'),expression:"finishStatus == 'Success'"}]},[_vm._v(" 我們已經為您建立了屬於您的帳號，您可以使用 "+_vm._s(_vm.email)+" 登入本應用。 ")]),_c('blockquote',{directives:[{name:"show",rawName:"v-show",value:(_vm.finishStatus == 'SuccessWithLogin'),expression:"finishStatus == 'SuccessWithLogin'"}]},[_vm._v(" 我們已經為您建立了屬於您的帳號，並幫您於此裝置進行登入，您可以立即開始使用應用，未來您可以使用 "+_vm._s(_vm.email)+" 進行登入。 ")]),_c('blockquote',[_vm._v(" 如果您在未來的使用過程中遇到任何問題，歡迎您與我們的團隊進行聯絡。 ")])]),_c('div',{staticClass:"col-12 mt-4 text-center text-color-sub"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.finishStatus == 'Success'),expression:"finishStatus == 'Success'"}]},[_vm._m(0)]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.finishStatus == 'SuccessWithLogin'),expression:"finishStatus == 'SuccessWithLogin'"}]},[_vm._m(1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',{staticClass:"fa-solid fa-clock fa-fade"}),_vm._v(" 稍後返回登入畫面")])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',{staticClass:"fa-solid fa-clock fa-fade"}),_vm._v(" 準備重新載入應用")])
}]

export { render, staticRenderFns }