<template>
	<div class="views-ui-startup-wrapper">
		<div class="views-ui-startup-content">
			<div class="row">
				<div class="col-12 text-center">
					<appIconVue size="72" radius="24" ref="appIcon" style="opacity: 0" />
				</div>
				<div class="col-12 mt-4 text-center" v-show="showReload">
					無法連線到伺服器
				</div>
				<div class="col-12 mt-1 text-center" v-show="showReload">
					<button class="btn btn-sm btn-danger" @click="onReloadClick">
						<i class="fa-solid fa-rotate"></i> 重新載入
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appIconVue from "@/components/global/appIcon.vue";
import router from "@/router";
import store from "@/store";
import userApi from "@/api/user";
import msgCtrl from "@/utils/msgCtrl";
import userCtrl from "@/utils/userCtrl";
import moment from "moment";
export default {
	name: "view-ui-startup",
	props: [],
	data() {
		return {
			showReload: false,
			initDate: null,
		};
	},
	components: {
		appIconVue,
	},
	computed: {},
	watch: {},
	methods: {
		onReloadClick() {
			var vm = this;
			vm.initDate = moment();
			vm.showReload = false;
			setTimeout(() => {
				vm.setAppIcon(vm.$refs.appIcon);
			}, 50);
			vm.getUserEntity();
		},
		getUserEntity() {
			var vm = this;
			userApi
				.getUserEntity()
				.then((res) => {
					let waitTime = 0;
					if (vm.initDate) {
						waitTime = 3000 - moment().diff(vm.initDate, "milliseconds");
					}
					if (!res.IsLogin) {
						userCtrl.setUserEntityClear();
						userCtrl.setUserTokenClear();
						store.dispatch("rootStore/appStartup");
						setTimeout(() => {
							router.push({ name: "login", replace: true });
						}, waitTime);
						return;
					}
					if (res.IsLogin) {
						store.dispatch("rootStore/userEntityLoad", res);
						store.dispatch("rootStore/appStartup");
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.ok,
							"登入成功",
							res.DisplayName + "，歡迎回來",
							msgCtrl.notifyDelay.default
						);
						setTimeout(() => {
							vm.goToStartUpBack();
						}, waitTime);
						return;
					}
				})
				.catch(() => {
					let waitTime = 0;
					if (vm.initDate) {
						waitTime = 5000 - moment().diff(vm.initDate, "milliseconds");
					}
					setTimeout(() => {
						msgCtrl.showNotifyDefault(
							msgCtrl.iconList.error,
							"連線失敗",
							"連線取得初始化資訊時出現錯誤，請確認裝置是否能進行連線，或是稍後再重試",
							msgCtrl.notifyDelay.default
						);
						vm.showReload = true;
						setTimeout(() => {
							vm.setAppIcon(vm.$refs.appIcon);
						}, 50);
					}, waitTime);
				})
				.finally(() => {
					if (vm.btnSendStatus == "wait") vm.setSendMailBtnStatus("normal");
				});
		},
		goToStartUpBack() {
			var vm = this;
			if (store.state.rootStore.router.startupBack == null) {
				if (
					store.state.rootStore.userEntity == null ||
					!store.state.rootStore.userEntity.IsLogin
				) {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"邏輯錯誤",
						"初始化過程出現邏輯錯誤，登入資訊沒有正確寫入到瀏覽器，請重新整理整個頁面",
						msgCtrl.notifyDelay.default
					);
				} else {
					vm.setAppIconHide();
					setTimeout(() => {
						router.push({ name: "home", replace: true });
					}, 450);
				}
			} else {
				if (
					store.state.rootStore.userEntity == null ||
					!store.state.rootStore.userEntity.IsLogin
				) {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"邏輯錯誤",
						"初始化過程出現邏輯錯誤，登入資訊沒有正確寫入到瀏覽器，請重新整理整個頁面",
						msgCtrl.notifyDelay.default
					);
				} else {
					let name = store.state.rootStore.router.startupBack.toName;
					let query = store.state.rootStore.router.startupBack.query;
					store.dispatch("rootStore/routerStartupBack", null);

					vm.setAppIconHide();
					if (name == "login") {
						name = "home";
						query = "";
					}
					setTimeout(() => {
						router.push({
							name: name,
							query: query,
							replace: true,
						});
					}, 450);
				}
			}
		},
		setAppIcon(appIcon) {
			var vm = this;
			let target = store.state.rootStore.appIconTrans;
			if (target) {
				if (target.status == "show") target.setTarget(appIcon, 0.5, "show");
				else target.setFadeToTarget(appIcon, "show");
			} else {
				setTimeout(() => {
					vm.setAppIcon(appIcon);
				}, 10);
			}
		},
		setAppIconHide() {
			var vm = this;
			let target = store.state.rootStore.appIconTrans;
			if (target) {
				if (target.status == "show")
					target.setFadeToTarget(vm.$refs.appIcon, "hide");
			} else {
				setTimeout(() => {
					vm.setAppIconHide();
				}, 10);
			}
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		vm.initDate = moment();
		vm.setAppIcon(vm.$refs.appIcon);
		vm.getUserEntity();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-startup-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.views-ui-startup-content {
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
