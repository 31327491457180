<template >
	<div class="menu-ui-wrapper">
		<span class="title">{{ menuData.menuGroupName }}</span>
		<menuItemVue v-for="(item, i) in menuItemList" :key="i" :itemData="item" />
	</div>
</template>

<script>
import menuItemVue from "./menuItem.vue";
export default {
	name: "menuUI",
	props: ["menuData", "clickEvent"],
	data() {
		return {};
	},
	computed: {
		menuItemList: {
			get() {
				var vm = this;
				return vm.menuData.meniItemList;
			},
		},
	},
	components: {
		menuItemVue,
	},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
