<template>
	<div>
		<homeVue />
	</div>
</template>

<script>
import homeVue from "@/views-ui/home/home.vue";
export default {
	name: "HomeView",
	data() {
		return {};
	},
	components: {
		homeVue,
	},
	methods: {},
	mounted() {},
};
</script>
<style lang="less" scoped></style>