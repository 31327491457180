<template>
	<div class="component-wavebg-wrapper">
		<section>
			<div class="air air4" :class="{ stop: waveStatus }"></div>
			<div class="air air3" :class="{ stop: waveStatus }"></div>
			<div class="air air2" :class="{ stop: waveStatus }"></div>
			<div class="air air1" :class="{ stop: waveStatus }"></div>
		</section>
	</div>
</template>

<script>
import store from "@/store";
export default {
	name: "waveBg",
	props: [],
	data() {
		return {};
	},
	computed: {
		waveStatus: {
			get() {
				return !store.state.rootStore.waveStatus;
			},
		},
	},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-wavebg-wrapper {
	position: relative;
	height: 100%;
}
section {
	position: relative;
	width: 100%;
	height: 50%;
	background: #3586ff;
	overflow: hidden;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}
section .air {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: url("@/assets/wave.png");
	background-size: 200% 100px;
	background-position-x: 100px;
}
/* @media (max-width: 576px) {
	section .air {
		background: url("@/assets/wave_home.png");
		background-size: 200% 100px;
	}
} */
section .air.air1 {
	animation: wave 30s linear infinite;
	opacity: 1;
	animation-delay: 0s;
	bottom: 0;
}
section .air.air2 {
	animation: wave2 15s linear infinite;
	opacity: 0.5;
	animation-delay: -5s;
	bottom: 10px;
}
section .air.air3 {
	animation: wave 30s linear infinite;
	opacity: 0.2;
	animation-delay: -2s;
	bottom: 15px;
}
section .air.air4 {
	animation: wave2 5s linear infinite;
	opacity: 0.7;
	animation-delay: -5s;
	bottom: 20px;
}
@media (max-width: 576px) {
	section .air.air1 {
		animation: wave 10s linear infinite;
	}
	section .air.air2 {
		animation: wave2 5s linear infinite;
	}
	section .air.air3 {
		animation: wave 7s linear infinite;
	}
	section .air.air4 {
		animation: wave2 7s linear infinite;
	}
}
section .air.stop {
	animation: none !important;
}
@keyframes wave {
	0% {
		background-position-x: 0px;
	}
	100% {
		background-position-x: 200%;
	}
}
@keyframes wave2 {
	0% {
		background-position-x: 0px;
	}
	100% {
		background-position-x: -200%;
	}
}
</style>
