<template >
	<div class="views-setting-page-wrapper">
		<div class="row">
			<div class="col-12 mt-4 mb-2">
				<button class="btn-back" @click="onBackBtnClick">
					<i class="fa-solid fa-arrow-left"></i>
				</button>
			</div>
			<div
				class="col-12 text-center mb-2"
				:style="{
					'--color':
						'rgb(' +
						nameToColor[0] +
						',' +
						nameToColor[1] +
						',' +
						nameToColor[2] +
						')',
				}"
			>
				<i class="fa-solid fa-circle-user fa-6x color-by-name"></i>
			</div>
			<div class="col-12 text-center mt-2 mb-2">
				<h3><strong>個人化設定</strong></h3>
			</div>
			<div class="col-12 mt-2 mb-2">
				<blockquote>
					現在我們已經確認了您的 Email
					帳號了，在完成註冊前我們想要知道該如何稱呼您。稱呼將會顯示在應用中，作為您的顯示名稱使用。
				</blockquote>
			</div>
			<div class="col-2 col-md-3"></div>
			<div class="col-8 col-md-6 mt-2 text-center">
				<input
					class="tb-normal full-width"
					v-model="displayName"
					maxlength="12"
					placeholder="輸入您的稱呼"
					@keyup="onDisplayNameKeyUp"
				/>
			</div>
			<div class="col-2 col-md-3"></div>
			<div class="col-2 col-md-3"></div>
			<div class="col-8 col-md-6 mb-2 mt-2 text-danger">
				<small v-show="!alertStatus.checkLength"
					><i class="fa-solid fa-circle-exclamation"></i> 稱呼的長度為
					3到12個中英文數字<br
				/></small>
				<small v-show="!alertStatus.checkChar"
					><i class="fa-solid fa-circle-exclamation"></i> 不可使用特殊符號<br
				/></small>
			</div>
			<div class="col-2 col-md-3"></div>
			<div class="col-12 mt-5 text-center">
				<button
					class="btn-normal"
					:class="[sendButtonStatus.color]"
					:disabled="sendButtonStatus.disabled"
					@click="onSendBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="sendButtonStatus.text">
							<i
								v-show="sendButtonStatus.icon == 'normal'"
								class="fa-solid fa-arrow-right"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'active'"
								class="fa-solid fa-arrow-right"
							></i>
							<i
								v-show="sendButtonStatus.icon == 'wait'"
								class="fa-solid fa-arrows-rotate fa-spin"
							></i>
							{{ sendButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import msgCtrl from "@/utils/msgCtrl";
import globalUtils from "@/utils/globalUtils";
import registerApi from "@/api/register";
import userCtrl from "@/utils/userCtrl";
import moment from "moment";
export default {
	name: "view-ui-register-setting",
	props: [],
	data() {
		return {
			sendButtonStatus: {
				color: "primary",
				text: "完成註冊",
				icon: "normal",
				disabled: true,
				status: "",
			},
			sendButtonList: {
				normal: {
					color: "primary",
					text: "完成註冊",
					icon: "normal",
					disabled: true,
					status: "",
				},
				active: {
					color: "primary",
					text: "完成註冊",
					icon: "normal",
					disabled: false,
					status: "",
				},
				wait: {
					color: "",
					text: "正在處理",
					icon: "wait",
					disabled: true,
					status: "",
				},
			},
		};
	},
	components: {},
	computed: {
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.parentVM.model.email;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.parentVM.model.displayName;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.displayName = value;
			},
		},
		finishStatus: {
			get() {
				var vm = this;
				return vm.parentVM.model.finishStatus;
			},
			set(value) {
				var vm = this;
				vm.parentVM.model.finishStatus = value;
			},
		},
		verifyUID: {
			get() {
				var vm = this;
				return vm.parentVM.model.verifyUID;
			},
		},
		activeKey1: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key1;
			},
		},
		activeKey2: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key2;
			},
		},
		activeKey3: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key3;
			},
		},
		activeKey4: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key4;
			},
		},
		activeKey5: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key5;
			},
		},
		activeKey6: {
			get() {
				var vm = this;
				return vm.parentVM.model.activeKeyList.key6;
			},
		},
		activeKey: {
			get() {
				var vm = this;
				return (
					vm.activeKey1 +
					vm.activeKey2 +
					vm.activeKey3 +
					vm.activeKey4 +
					vm.activeKey5 +
					vm.activeKey6
				);
			},
		},
		nameToColor: {
			get() {
				var vm = this;
				if (!vm.alertStatus.checkLength) return [0, 0, 0];
				return globalUtils.getNameToColor(vm.displayName);
			},
		},
		alertStatus: {
			get() {
				var vm = this;
				let result = {
					checkLength: true,
					checkChar: true,
				};
				if (vm.displayName.length < 3 || vm.displayName > 12)
					result.checkLength = false;
				if (vm.checkSpecialChar(vm.displayName)) result.checkChar = false;
				return result;
			},
		},
		isCanSend: {
			get() {
				var vm = this;
				let result = true;
				if (!vm.alertStatus.checkLength) result = false;
				if (!vm.alertStatus.checkChar) result = false;
				return result;
			},
		},
	},
	watch: {
		isCanSend(newValue) {
			var vm = this;
			if (newValue) vm.setSendButtonStatus("active");
			else vm.setSendButtonStatus("normal");
		},
	},
	methods: {
		setInital() {
			var vm = this;
			vm.displayName = "";
			vm.setSendButtonStatus("normal");
		},
		setSendButtonStatus(name) {
			var vm = this;
			vm.sendButtonStatus = JSON.parse(JSON.stringify(vm.sendButtonList[name]));
		},
		onDisplayNameKeyUp(e) {
			var vm = this;
			if (e.key === "Enter" && vm.isCanSend) {
				vm.callSetRegData();
				return;
			}
		},
		onSendBtnClick() {
			var vm = this;
			if (vm.isCanSend) vm.callSetRegData();
		},
		onBackBtnClick() {
			var vm = this;
			msgCtrl.showMsgBoxYesNo(
				msgCtrl.iconList.alert,
				"取消註冊",
				"註冊尚未完成，請問是否要取消呢？",
				"取消註冊",
				msgCtrl.buttonList.danger,
				"返回",
				msgCtrl.buttonList.light,
				() => {
					vm.parentVM.setPage("welcome");
				},
				() => {}
			);
		},
		checkSpecialChar(str) {
			var pattern = new RegExp(/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/);
			return pattern.test(str);
		},
		callSetRegData() {
			var vm = this;

			vm.setSendButtonStatus("wait");
			let dateNow = moment();

			registerApi
				.setRegData({
					UID: vm.verifyUID,
					Email: vm.email,
					ActiveKey: vm.activeKey,
					DisplayName: vm.displayName,
				})
				.then((res) => {
					let waitTime = 3000 - moment().diff(dateNow, "milliseconds");
					if (waitTime < 0) waitTime = 0;
					setTimeout(() => {
						if (res.Success) {
							if (res.Action == "Failed") {
								msgCtrl.showNotifyDefault(
									msgCtrl.iconList.alert,
									"註冊失敗",
									res.Message,
									msgCtrl.notifyDelay.default
								);
								vm.setRegisterBtnStatus("normal");
								return;
							}

							if (res.Action == "Timeout") {
								msgCtrl.showNotifyDefault(
									msgCtrl.iconList.alert,
									"驗證已過期",
									"您的驗證資訊已經逾期，請重新進行 Email 驗證",
									msgCtrl.notifyDelay.default
								);
								vm.parentVM.setPage("verify");
								vm.setRegisterBtnStatus("normal");
								return;
							}
							if (res.Action == "Success") {
								vm.finishStatus = "Success";
								vm.parentVM.setPage("finish");
								return;
							}
							if (res.Action == "SuccessWithLogin") {
								vm.finishStatus = "SuccessWithLogin";
								userCtrl.setUserToken(res.Data[0]);
								vm.parentVM.setPage("finish");
								return;
							}
						} else {
							msgCtrl.showNotifyDefault(
								msgCtrl.iconList.alert,
								"註冊失敗",
								"進行註冊流程時發生錯誤，請稍後再重試。如果持續發生錯誤，請與我們聯絡",
								msgCtrl.notifyDelay.default
							);
							vm.setSendButtonStatus("active");
							return;
						}
					}, waitTime);
				})
				.catch(() => {
					msgCtrl.showNotifyDefault(
						msgCtrl.iconList.error,
						"連線失敗",
						"連線進行註冊時發生錯誤，請稍後再次重試，如果問題持續發生，請與我們聯絡",
						msgCtrl.notifyDelay.default
					);
					vm.setSendButtonStatus("active");
				})
				.finally(() => {});
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-setting-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-setting-page-wrapper {
		padding-bottom: 3rem;
	}
}
@media (max-height: 768px) and (max-width: 576px) {
	.views-setting-page-wrapper {
		align-items: flex-start;
		padding-bottom: 2rem;
		overflow: hidden;
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.color-by-name {
	color: var(--color);
	transition: 0.5s;
}
</style>
