import Vue from "vue";
import Vuex from "vuex";
import msgStore from "./msgStore";
import rootStore from "./rootStore";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		rootStore: rootStore,
		msgStore: msgStore,
	},
});
