<template >
	<div class="views-welcome-page-wrapper">
		<div class="row">
			<div class="col-12 mb-4">
				<button class="btn-back" @click="onBackBtnClick">
					<i class="fa-solid fa-arrow-left"></i>
				</button>
			</div>
			<div class="col-12 mb-2 text-center">
				<appIconVue size="96" radius="24" style="opacity: 0" ref="appIcon" />
			</div>
			<div class="col-12 mt-2 mb-4 text-center">
				<h1 class="m-0"><strong>Cash Tracert</strong></h1>
				<small class="text-color-sub">在您手上的隨身記帳助理</small>
			</div>
			<div class="col-12 mt-2 mb-2">
				<blockquote>
					歡迎註冊 Cash
					Tracert，接下來的步驟中，我們將幫您建立一個屬於您的帳號。
				</blockquote>
				<blockquote>
					您將可在您的所有裝置使用您的帳號登入，並隨時查看所有您的資料。
				</blockquote>
			</div>
			<div class="col-12 mt-4 text-center">
				<button
					class="btn-normal"
					:class="[sendButtonStatus.color]"
					:disabled="sendButtonStatus.disabled"
					@click="onSendBtnClick"
				>
					<transition name="text-fade" mode="out-in">
						<span :key="sendButtonStatus.text">
							<i
								v-show="sendButtonStatus.icon == 'normal'"
								class="fa-solid fa-arrow-right"
							></i>
							{{ sendButtonStatus.text }}
						</span>
					</transition>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import appIconVue from "@/components/global/appIcon.vue";
export default {
	name: "view-ui-register-welcome",
	props: [],
	data() {
		return {
			sendButtonStatus: {
				color: "primary",
				text: "讓我們開始吧",
				icon: "normal",
				disabled: false,
				status: "",
			},
		};
	},
	components: { appIconVue },
	computed: {
		appIcon: {
			get() {
				var vm = this;
				return vm.$refs.appIcon;
			},
		},
		parentVM: {
			get() {
				var vm = this;
				return vm.$parent.$parent.$parent;
			},
		},
	},
	watch: {},
	methods: {
		setInital() {},
		onSendBtnClick() {
			var vm = this;
			vm.parentVM.setPage("license");
		},
		onBackBtnClick() {
			var vm = this;
			vm.parentVM.switchToLogin();
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-welcome-page-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
}
@media (max-width: 576px) {
	.views-welcome-page-wrapper {
		padding-bottom: 4rem;
	}
}
</style>
