<template>
	<div class="views-ui-userinfo-wrapper" :class="[menuStatus]">
		<div
			class="views-ui-userinfo-dim"
			:class="{ show: userInfoStatus == 'view-large' }"
			@click="onChangeBtnClick"
		></div>
		<div
			class="views-ui-userinfo"
			:class="[userInfoStatus]"
			:style="{
				'--color':
					'rgb(' +
					nameToColor[0] +
					',' +
					nameToColor[1] +
					',' +
					nameToColor[2] +
					')',
			}"
		>
			<div class="views-ui-userinfo-bg"></div>
			<div class="views-ui-userinfo-content">
				<div class="views-ui-userinfo-base">
					<div class="views-ui-userinfo-icon">
						{{ iconChar }}
					</div>
					<div class="views-ui-userinfo-text text-truncate">
						<span>{{ displayName }}</span
						><br />
						<span>{{ email }}</span>
					</div>
				</div>
				<div class="views-ui-userinfo-more">
					<button class="view-ui-userinfo-item">
						<i class="fa-solid fa-gear"></i> 選項設定
					</button>
					<button class="view-ui-userinfo-item">
						<i class="fa-solid fa-user"></i> 帳號設定
					</button>
					<button class="view-ui-userinfo-item">
						<i class="fa-solid fa-right-from-bracket"></i> 登出帳號
					</button>
				</div>
			</div>
			<button
				class="view-ui-userinfo-change-btn"
				@click="onChangeBtnClick"
			></button>
		</div>
	</div>
</template>

<script>
import globalUtils from "@/utils/globalUtils";
import store from "@/store";
export default {
	name: "view-ui-menu-userInfo",
	props: [],
	data() {
		return {
			userInfoStatus: "",
		};
	},
	components: {},
	computed: {
		menuStatus: {
			get() {
				return store.state.rootStore.menuStatus;
			},
		},
		userEntity: {
			get() {
				return store.state.rootStore.userEntity;
			},
		},
		displayName: {
			get() {
				var vm = this;
				return vm.userEntity.DisplayName;
			},
		},
		email: {
			get() {
				var vm = this;
				return vm.userEntity.Email;
			},
		},
		nameToColor: {
			get() {
				var vm = this;
				if (vm.displayName == null) return [0, 0, 0];
				return globalUtils.getNameToColor(vm.displayName);
			},
		},
		iconChar: {
			get() {
				var vm = this;
				return vm.userEntity.DisplayName.substring(0, 1);
			},
		},
	},
	watch: {},
	methods: {
		onChangeBtnClick() {
			var vm = this;
			if (vm.userInfoStatus == "view-large") {
				vm.userInfoStatus = "view-small";
				setTimeout(() => {
					if (vm.userInfoStatus == "view-small") vm.userInfoStatus = "";
					vm.$parent.setMenuToTop(false);
				}, 500);
			} else {
				vm.userInfoStatus = "view-large";
				vm.$parent.setMenuToTop(true);
			}
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-userinfo-wrapper {
	position: absolute;
	left: 8px;
	bottom: 8px;
}
@media (max-width: 576px) {
	.views-ui-userinfo-wrapper {
		transform: translate(0, 150px);
		transition: 0.5s;
	}
	.views-ui-userinfo-wrapper.show {
		transform: translate(0, 0);
		transition: 0.25s;
	}
}

.views-ui-userinfo-dim {
	position: absolute;
	bottom: -8px;
	left: -8px;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0);
	display: none;
}
.views-ui-userinfo-dim.show {
	display: block;
}
.views-ui-userinfo {
	position: relative;
	padding: 0.5rem 0 0.5rem 0;
	width: 240px;
	height: auto;
	min-height: 55px;
	max-height: 55px;
	color: #000;
	transition: 0.5s ease-in-out;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	opacity: 1;
}
@media (min-width: 577px) {
	.views-ui-userinfo {
		height: auto;
		min-height: 55px;
		max-height: 55px;
		transition: 0.5s ease-in-out;
	}

	.views-ui-userinfo.view-large {
		margin-left: 8px;
		margin-bottom: 8px;
		width: 320px;
		height: auto;
		max-height: 40vh;
		align-items: flex-start;
		transition: 0.35s ease-in-out;
	}
	.views-ui-userinfo.view-small {
		align-items: flex-start;
		transition: 0.5s ease-in-out;
	}
}
@media (max-width: 576px) {
	.views-ui-userinfo {
		height: auto;
		min-height: 64px;
		max-height: 64px;
		transition: 0.5s ease-in-out;
	}

	.views-ui-userinfo.view-large {
		margin-left: 8px;
		margin-bottom: 8px;
		width: calc(100vw - 32px);
		height: auto;
		max-height: 40vh;
		align-items: flex-start;
		transition: 0.35s ease-in-out;
	}
	.views-ui-userinfo.view-small {
		align-items: flex-start;
		transition: 0.5s ease-in-out;
	}
}

.views-ui-userinfo-icon {
	position: absolute;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 3rem;
	margin-left: 0.75rem;
	margin-right: 1.5rem;
	font-weight: bold;
	font-size: 1.25rem;
	background: var(--color);
}
.views-ui-userinfo-icon::before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: -0.75rem;
	border-right: solid 1px rgba(200, 200, 200, 0.55);
}
.views-ui-userinfo-content {
	width: 100%;
}
.views-ui-userinfo-text {
	position: relative;
	display: inline-block;
	margin-left: 4.5rem;
	align-items: center;
	text-align: start;
	white-space: nowrap;
	min-width: 120px;
}
.views-ui-userinfo-base {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	transition: 0.5s;
}
.views-ui-userinfo-more {
	position: relative;
	max-height: 0;
	opacity: 0;
	padding: 0 8px;
	transition: 0.5s;
	width: 240px;
	overflow: hidden;
}

@media (min-width: 577px) {
	.views-ui-userinfo.view-large .views-ui-userinfo-more {
		max-height: 300px;
		padding: 16px 8px 0 8px;
		width: 320px;
		opacity: 1;
	}
}
@media (max-width: 576px) {
	.views-ui-userinfo.view-large .views-ui-userinfo-more {
		max-height: 300px;
		padding: 16px 8px 0 8px;
		width: calc(100vw - 32px);
		opacity: 1;
	}
}
.views-ui-userinfo-text span:first-child {
	font-weight: bold;
}
.views-ui-userinfo-text span:last-child {
	font-size: 0.85rem;
}
.views-ui-userinfo-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0);
	border: solid 1px rgba(200, 200, 200, 0);
	border-radius: 16px;
	transition: 0.5s;
}
.views-ui-userinfo:hover .views-ui-userinfo-bg {
	background: rgba(240, 240, 240, 1);
	border: solid 1px rgba(200, 200, 200, 0.55);
	transition: 0.25s;
}
.views-ui-userinfo.view-large .views-ui-userinfo-bg {
	background: rgba(255, 255, 255, 1);
	border: solid 1px rgba(200, 200, 200, 0.55);
	box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.125);
}

.views-ui-userinfo.view-large:hover .views-ui-userinfo-bg {
	background: #fff;
}

@media (min-width: 577px) {
	.views-ui-userinfo.view-large .views-ui-userinfo-base {
		padding: 8px;
	}
	.views-ui-userinfo.view-large .views-ui-userinfo-base::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -0.25rem;
		border-bottom: solid 1px rgba(200, 200, 200, 0.55);
		transition: 0.5s;
	}
}
@media (max-width: 576px) {
	.views-ui-userinfo.view-large .views-ui-userinfo-base {
		margin: 0;
		padding: 4px;
		transition: 0.5s;
		width: calc(100vw - 32px);
	}
	.views-ui-userinfo.view-large .views-ui-userinfo-base::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -0.25rem;
		border-bottom: solid 1px rgba(200, 200, 200, 0.55);
		transition: 0.5s;
	}
}

.views-ui-userinfo::before {
	content: "";
	position: absolute;
	top: -1px;
	left: 0;
	right: 0;
	bottom: 0;
	border-top: solid 1px rgba(200, 200, 200, 0.55);
	transition: 0.5s;
}
.views-ui-userinfo:hover::before {
	border-top: solid 1px rgba(200, 200, 200, 0);
}

.views-ui-userinfo.view-large::before {
	border-top: solid 1px rgba(200, 200, 200, 0);
}

.view-ui-userinfo-change-btn {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: none;
	background: transparent;
}
.views-ui-userinfo.view-large .view-ui-userinfo-change-btn {
	display: none;
}

.view-ui-userinfo-item {
	border: solid 1px rgba(200, 200, 200, 0);
	background: transparent;
	border-radius: 16px;
	padding: 12px 16px;
	width: 100%;
	transition: 0.5s;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.view-ui-userinfo-item svg {
	margin-right: 8px;
}
.view-ui-userinfo-item:hover {
	border: solid 1px rgba(200, 200, 200, 0.55);
	background: rgba(240, 240, 240, 1);
	transition: 0.25s;
}
.view-ui-userinfo-item:active {
	transform: scale(0.95);
	transition: 0.25s;
}
</style>
