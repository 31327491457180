var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msg-wrapper",class:[_vm.wrapperstatus],style:({
		opacity: _vm.rootOpacity,
		transition: _vm.rootTrans + 's',
		transitionTimingFunction: _vm.rootTiming,
	})},[_c('div',{staticClass:"msg-wrapper-bg",style:({
			backdropFilter: 'blur(' + _vm.wrapperBlur + 'px)',
			transition: _vm.wrapperTrans + 's',
			transitionTimingFunction: _vm.wrapperTiming,
		})}),_c('div',{staticClass:"msg-content-wrapper"},[_c('div',{staticClass:"msg-content-wrapper-bg",on:{"click":_vm.onWrapperClick}}),_c('div',{staticClass:"msg-content-frame",style:({
				opacity: _vm.msgOpacity,
				transform: 'scale(' + _vm.msgZoom + ', ' + _vm.msgZoom + ')',
				pointerEvents: _vm.msgPointEvent,
				transition: _vm.msgTrans + 's',
				transitionTimingFunction: _vm.msgTiming,
			})},[(_vm.msgData != null)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('i',{staticClass:"fa-6x",class:[_vm.msgData.icon.icon, _vm.msgData.icon.color]})])]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('h5',[_c('strong',[_vm._v(_vm._s(_vm.msgData.msg.title))])])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"msg-content-frame-msgcontent",domProps:{"innerHTML":_vm._s(_vm.msgData.msg.message)}})])]),(_vm.msgData.btn.length == 1)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[0].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[0])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[0].text)+" ")])])]):_vm._e(),(_vm.msgData.btn.length == 2)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 pe-2"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[1].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[1])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[1].text)+" ")])]),_c('div',{staticClass:"col-6 ps-2"},[_c('button',{staticClass:"btn w-100",class:[_vm.msgData.btn[0].type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, _vm.msgData.btn[0])}}},[_vm._v(" "+_vm._s(_vm.msgData.btn[0].text)+" ")])])]):_vm._e(),(_vm.msgData.btn.length > 2)?_c('div',{staticClass:"row"},_vm._l((_vm.msgData.btn),function(btn,i){return _c('div',{key:i,staticClass:"col-12",class:{
							'mt-4': i == _vm.msgData.btn.length - 1,
							'mt-2': i != _vm.msgData.btn.length - 1,
						}},[_c('button',{staticClass:"btn w-100",class:[btn.type],on:{"click":function($event){return _vm.runMethod(_vm.msgData, btn)}}},[_vm._v(" "+_vm._s(btn.text)+" ")])])}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"msg-content-frame-border"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }