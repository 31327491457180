<template>
	<div class="component-appbg-wrapper">
		<div class="component-appbg-img"></div>
	</div>
</template>

<script>
export default {
	name: "appBg",
	props: [],
	data() {
		return {};
	},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.component-appbg-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.component-appbg-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url("@/assets/mainBg.webp");
	background-position: center center;
	background-size: cover;
}
</style>
