<template>
	<div class="views-ui-main-wrapper">
		<appBg />
		<layerUIVue
			v-for="(layer, i) in layerList"
			:key="i"
			:ref="'layerItem_' + i"
			:layerList="layerList"
			:layerData="layer"
		>
			<component :is="layer.componentName"></component>
		</layerUIVue>
	</div>
</template>

<script>
import appBg from "@/components/global/appBg";
import layerUIVue from "@/components/layerUI/layerUI.vue";
import frameLayerVue from "@/views-ui/home/frame/main.vue";
export default {
	name: "view-ui-home",
	props: [],
	data() {
		return {
			layerList: [
				{
					componentName: "frameLayer",
					layerType: "main",
					status: "normal",
				},
			],
		};
	},
	components: {
		frameLayer: frameLayerVue,
		layerUIVue,
		appBg,
	},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-main-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: transparent;
}
</style>
