<template>
	<div class="views-ui-content-wrapper"></div>
</template>

<script>
export default {
	name: "view-ui-content",
	props: [],
	data() {
		return {
			showReload: true,
			initDate: null,
		};
	},
	components: {},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-content-wrapper {
	position: absolute;
	top: 0.5rem;
	left: 260px;
	right: 0.5rem;
	bottom: 0.5rem;
	border-radius: 16px;
	transition: 0.35s;
}
@media (max-width: 992px) {
	.views-ui-content-wrapper {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.views-ui-content-menubtn {
	position: absolute;
	z-index: 8;
	left: 24px;
	top: 8px;
	height: 36px;
	width: 36px;
	font-size: 1.35rem;
	border: solid 1px rgba(0, 0, 0, 0);
	background: rgba(0, 0, 0, 0);
	color: #333;
	border-radius: 16px;
	transition: 0.5s;
}
.views-ui-content-menubtn:hover {
	color: #000;
	transition: 0.25s;
}
.views-ui-content-menubtn:active {
	color: #666;
	transition: 0.25s;
}
</style>
