import client from "@/api/client";

export default {
	verifyEmail: (data) => {
		return client({
			url: "api/Register/VerifyEmail",
			method: "post",
			data,
		});
	},
	checkActiveKey: (data) => {
		return client({
			url: "api/Register/CheckActiveKey",
			method: "post",
			data,
		});
	},
	setRegData: (data) => {
		return client({
			url: "api/Register/SetRegData",
			method: "post",
			data,
		});
	},
};
