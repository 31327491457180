import userCtrl from "@/utils/userCtrl";
export default {
	namespaced: true,
	state: {
		//初始化
		isLoading: false,
		isStartup: false,
		//App Icon
		appIconTrans: null,
		//使用者資訊
		userEntity: null,
		//路由自動回推
		router: {
			startupBack: null,
			loginBack: null,
		},
		//選單開關
		menuStatus: "",
		//手機版下方選單
		frameBottom: null,
		//當前 List 捲動狀態
		scrollBarY: 0,
		//背景 Wave 動畫
		waveStatus: true,
	},
	getters: {},
	mutations: {
		setLoading(state, v) {
			state.isLoading = v;
		},
		setStartup(state, v) {
			state.isStartup = v;
		},
		setAppIconTrans(state, v) {
			state.appIconTrans = v;
		},
		setUserEntity(state, v) {
			state.userEntity = v;
		},
		setRouterStartupBack(state, v) {
			state.router.startupBack = v;
		},
		setRouterLoginBack(state, v) {
			state.router.loginBack = v;
		},
		setMenuStatus(state, v) {
			state.menuStatus = v;
		},
		setFrameBottom(state, v) {
			state.frameBottom = v;
		},
		setScrollBarY(state, v) {
			state.scrollBarY = v;
			if (state.frameBottom != null) state.frameBottom.setScrollBarY(v);
		},
		setWaveStatus(state, v) {
			state.waveStatus = v;
		},
	},
	actions: {
		progressInfoIn(context) {
			context.commit("setLoading", true);
		},
		progressInfoOut(context) {
			context.commit("setLoading", false);
		},
		appStartup(context) {
			context.commit("setStartup", true);
		},
		userEntityLoad(context, input) {
			userCtrl.setUserEntity(input);
			context.commit("setUserEntity", input);
		},
		setAppIconTrans(context, input) {
			context.commit("setAppIconTrans", input);
		},
		routerStartupBack(context, input) {
			context.commit("setRouterStartupBack", input);
		},
		routerLoginBack(context, input) {
			context.commit("setRouterLoginBack", input);
		},
		setMenuStatus(context, input) {
			context.commit("setMenuStatus", input);
		},
		setFrameBottom(context, input) {
			context.commit("setFrameBottom", input);
		},
		setScrollBarY(context, input) {
			context.commit("setScrollBarY", input);
		},
		setWaveStatus(context, input) {
			context.commit("setWaveStatus", input);
		},
	},
};
