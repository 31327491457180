import axios from "axios";
import userCtrl from "@/utils/userCtrl";

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"Cache-Control": "no-cache",
		pragma: "no-cache",
		post: {
			"Content-Type": "application/json;charset=UTF-8",
		},
	},
});

service.interceptors.request.use(
	(config) => {
		config.headers["SessionToken"] = userCtrl.getUserToken().UserToken;
		return config;
	},
	(error) => {
		console.log(error);
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		const res = response.data;
		return res;
	},
	(error) => {
		console.log("axios client error :" + error);
		return Promise.reject(error);
	}
);

export default service;
