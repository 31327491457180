<template>
	<div class="views-ui-menu-wrapper" :class="[menuToTop]">
		<div class="views-ui-menu-bg"></div>
		<div class="views-ui-menu-content">
			<menuUIVue
				v-for="(item, i) in menuData"
				:key="i"
				:menuData="item"
				:class="[{ show: menuStatus == 'show' }]"
			/>
		</div>
		<userinfoVue />
	</div>
</template>

<script>
import menuUIVue from "@/components/menuUI/menuUI.vue";
import userinfoVue from "../menu/userInfo.vue";
import store from "@/store";
export default {
	name: "view-ui-menu",
	props: [],
	data() {
		return {
			menuToTop: "",
			menuItemStatus: "",
			menuData: [
				{
					menuGroupName: "總覽",
					meniItemList: [
						{
							itemName: "資訊總覽",
							itemIcon: "fa-solid fa-house",
							itemType: "item",
						},
					],
				},
				{
					menuGroupName: "支出",
					meniItemList: [
						{
							itemName: "支出總覽",
							itemIcon: "fa-solid fa-table-list",
							itemType: "item",
						},
						{
							itemName: "依月份",
							itemIcon: "fa-solid fa-calendar",
							itemType: "item",
						},
						{
							itemName: "固定支出",
							itemIcon: "fa-solid fa-money-check-dollar",
							itemType: "item",
						},
						{
							itemName: "非固定支出",
							itemIcon: "fa-solid fa-money-bill-1-wave",
							itemType: "item",
						},
					],
				},
				{
					menuGroupName: "收入",
					meniItemList: [
						{
							itemName: "收入總覽",
							itemIcon: "fa-solid fa-table-list",
							itemType: "item",
						},
						{
							itemName: "依月份",
							itemIcon: "fa-solid fa-calendar",
							itemType: "item",
						},
						{
							itemName: "固定收入",
							itemIcon: "fa-solid fa-building",
							itemType: "item",
						},
						{
							itemName: "非固定收入",
							itemIcon: "fa-solid fa-briefcase",
							itemType: "item",
						},
					],
				},
			],
		};
	},
	components: {
		userinfoVue,
		menuUIVue,
	},
	computed: {
		menuStatus: {
			get() {
				return store.state.rootStore.menuStatus;
			},
		},
	},
	watch: {},
	methods: {
		setMenuToTop(input) {
			var vm = this;
			if (input) vm.menuToTop = "to-top";
			else vm.menuToTop = "";
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-menu-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 260px;
	z-index: 20;
	transform: translateX(0);
	padding-top: 8px;
}
@media (max-width: 992px) {
	.views-ui-menu-wrapper {
		z-index: initial;
		transform: translateX(0);
		padding-top: 64px;
		padding-bottom: 66px;
	}
	.views-ui-menu-wrapper.to-top {
		z-index: 20;
	}
}
.views-ui-menu-content {
	height: 100%;
	background: rgba(255, 255, 255, 0);
	overflow: hidden;
	overflow-y: auto;
}
.views-ui-menu-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
</style>
