export default {
	numberToCurrencyNo: numberToCurrencyNo,
	getChinesNum: getChinesNum,
};
function numberToCurrencyNo(value) {
	if (!value) return 0;
	const intPart = Math.trunc(value);
	const intPartFormat = intPart
		.toString()
		.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
	let floatPart = "";
	const valueArray = value.toString().split(".");
	if (valueArray.length === 2) {
		floatPart = valueArray[1].toString();
		return intPartFormat + "." + floatPart;
	}
	return intPartFormat + floatPart;
}

function getChinesNum(num) {
	let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"]; //changeNum[0] = "零"
	let unit = ["", "十", "百", "千", "萬"];
	num = parseInt(num);
	let getWan = (temp) => {
		let strArr = temp.toString().split("").reverse();
		let newNum = "";
		for (var i = 0; i < strArr.length; i++) {
			newNum =
				(i == 0 && strArr[i] == 0
					? ""
					: i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
					? ""
					: changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
				newNum;
		}
		return newNum;
	};
	let overWan = Math.floor(num / 10000);
	let noWan = num % 10000;
	if (noWan.toString().length < 4) noWan = "0" + noWan;
	return overWan ? getWan(overWan) + "萬" + getWan(noWan) : getWan(num);
}
