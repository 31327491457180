<template >
	<div class="notify-list-wrapper">
		<div class="container position-relative h-100 overflow-hidden">
			<div class="notify-list-content-wrapper">
				<notifyItem
					v-for="item in notifyList"
					:key="item.uid"
					:inputData="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import notifyItem from "./notifyItem.vue";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "notifyList",
	props: {},
	data() {
		return {};
	},
	components: {
		notifyItem,
	},
	computed: {
		notifyList: {
			get() {
				return store.state.msgStore.notifyList;
			},
		},
		notifyCount: {
			get() {
				return store.state.msgStore.notifyList.length;
			},
		},
	},
	watch: {},
	methods: {
		removeNotify(obj) {
			msgCtrl.removeNotify(obj);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
