<template>
	<div>
		<div
			class="view-login-wrapper anime-component-scale"
			:class="[
				{
					render: statusData.loginRender,
				},
				statusData.loginStatus,
			]"
		>
			<loginVue ref="login" />
		</div>
		<div
			class="view-login-wrapper anime-component-scale"
			:class="[
				{
					render: statusData.registerRender,
				},
				statusData.registerStatus,
			]"
		>
			<registerVue ref="register" />
		</div>
	</div>
</template>

<script>
import loginVue from "@/views-ui/login/login.vue";
import registerVue from "@/views-ui/login/register.vue";
export default {
	name: "HomeView",
	data() {
		return {
			statusData: {
				registerRender: false,
				registerStatus: "",
				loginRender: false,
				loginStatus: "",
			},
		};
	},
	components: {
		loginVue,
		registerVue,
	},
	methods: {
		showLogin() {
			var vm = this;
			vm.statusData.loginRender = true;
			setTimeout(() => {
				vm.$refs.login.setAppIconToPage();
				setTimeout(() => {
					vm.statusData.loginStatus = "hide";
				}, 150);
			}, 200);

			setTimeout(() => {
				vm.$refs.login.setInital();
				vm.statusData.loginStatus = "show";
			}, 750);
		},
		hideLogin() {
			var vm = this;
			vm.statusData.loginStatus = "hide";
			setTimeout(() => {
				vm.statusData.loginRender = false;
				vm.statusData.loginStatus = "";
			}, 350);
		},
		showRegister() {
			var vm = this;
			vm.statusData.registerRender = true;
			setTimeout(() => {
				vm.$refs.register.setAppIconToPage();
				setTimeout(() => {
					vm.statusData.registerStatus = "hide";
				}, 150);
			}, 200);

			setTimeout(() => {
				vm.statusData.registerShow = true;
				vm.statusData.registerStatus = "show";
			}, 750);
		},
		hideRegister() {
			var vm = this;
			vm.statusData.registerStatus = "hide";
			setTimeout(() => {
				vm.statusData.registerRender = false;
				vm.statusData.registerStatus = "";
			}, 350);
		},
		switchToLogin() {
			var vm = this;
			vm.hideRegister();
			setTimeout(() => {
				vm.showLogin();
			}, 350);
		},
		switchToRegister(input) {
			var vm = this;
			vm.hideLogin();
			setTimeout(() => {
				vm.$refs.register.setInital(input);
				vm.showRegister();
			}, 350);
		},
	},
	mounted() {
		var vm = this;
		setTimeout(() => {
			vm.showLogin();
		}, 350);
	},
};
</script>
<style lang="less" scoped>
.view-login-wrapper {
	width: 100vw;
	height: 100vh;
}
</style>