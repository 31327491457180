var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"views-ui-frame-wrapper"},[_c('menuVue'),_c('div',{staticClass:"view-ui-frame-content",class:{
			'menu-show': _vm.menuBtnStatus == 'back',
			'menu-hide': _vm.menuBtnStatus == 'menu',
		},style:({
			'--moveX': _vm.contentStatus.moveX + 'px',
			'--moveY': _vm.contentStatus.moveY + 'px',
			'--scale': _vm.contentStatus.scale,
		})},[_c('dynamicVue'),_c('contentVue'),_c('div',{staticClass:"view-ui-frame-content-dim",class:{
				show: _vm.menuBtnStatus == 'back',
				hide: _vm.menuBtnStatus == 'menu',
			},on:{"click":function($event){return _vm.setMenuStatus('hide')}}})],1),_c('div',{staticClass:"views-ui-frame-menubtn"},[_c('button',{staticClass:"btn-menu",on:{"click":_vm.onMenuBtnClick}},[_c('transition',{attrs:{"name":"text-fade","mode":"out-in"}},[_c('span',{key:_vm.menuBtnStatus},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuBtnStatus == 'menu'),expression:"menuBtnStatus == 'menu'"}],staticClass:"fa-solid fa-bars"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuBtnStatus == 'back'),expression:"menuBtnStatus == 'back'"}],staticClass:"fa-solid fa-arrow-left"})])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }