<template >
	<div class="notify-list-item-wrapper" :class="[wrapperStatus]">
		<div class="notify-list-item" :class="[mainStatus]">
			<div class="notify-list-item-bg"></div>
			<div class="notify-list-item-content">
				<div class="text-wordbread">
					<div>
						<strong> {{ inputData.title }} </strong>
					</div>
					<div class="notify-list-item-html" v-html="inputData.message"></div>
					<div class="notify-list-item-text">{{ messageText }}</div>
				</div>
			</div>
			<div class="notify-list-item-icon">
				<span :class="[inputData.icon.color]">
					<i :class="[inputData.icon.icon]"></i>
				</span>
			</div>
			<div class="notify-list-item-close" @click="hideItem">
				<i class="fa-regular fa-circle-xmark"></i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "notifyItem",
	props: ["inputData"],
	data() {
		return {
			wrapperStatus: "",
			mainStatus: "",
			timer: null,
		};
	},
	computed: {
		messageText: {
			get() {
				var vm = this;
				let tmp = document.createElement("DIV");
				tmp.innerHTML = vm.inputData.message;
				return tmp.textContent || tmp.innerText || "";
			},
		},
	},
	watch: {},
	methods: {
		showItem() {
			var vm = this;
			vm.wrapperStatus = "show";
			setTimeout(() => {
				vm.mainStatus = "show";
			}, 750);
			let delay = 5000;
			if (vm.inputData.delay) delay = vm.inputData.delay;
			vm.timer = setTimeout(() => {
				vm.hideItem();
			}, delay);
		},
		hideItem() {
			var vm = this;
			vm.mainStatus = "hide";
			if (vm.timer != null) clearTimeout(vm.timer);
			setTimeout(() => {
				vm.wrapperStatus = "hide";
			}, 350);
			setTimeout(() => {
				vm.$parent.removeNotify(vm.inputData);
			}, 700);
		},
	},
	created: function () {},
	mounted: function () {
		var vm = this;
		vm.showItem();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
