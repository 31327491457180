<template>
	<div class="view-test-wrapper">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "TestView",
	data() {
		return {};
	},
	components: {},
	methods: {},
	mounted() {},
};
</script>
<style lang="less" scoped>
.view-test-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
</style>