<template >
	<div class="page-item-wrapper">
		<div class="page-item-content">
			<slot>
				<div class="row h-100">
					<div class="col-12">未設定頁面</div>
				</div>
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "pageUI",
	props: [],
	data() {
		return {};
	},
	computed: {},
	watch: {},
	methods: {},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
