var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-container container",style:({
			maxWidth: _vm.getMaxWidth,
		})},[_c('div',{ref:"container",staticClass:"page-container-landscape",style:({
				transform:
					'translate(' +
					_vm.pageStatus.left +
					'px' +
					',' +
					_vm.pageStatus.top +
					'px' +
					')',
			})},[_vm._t("default",function(){return [_vm._m(0)]})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12"},[_vm._v("未插入頁面")])])
}]

export { render, staticRenderFns }