<template>
	<div id="app">
		<router-view></router-view>
		<appIconTransVue ref="appIconTran" />
		<msgBoxVue />
		<notifyListVue />
	</div>
</template>

<script>
import msgBoxVue from "@/components/msgUI/msgBox.vue";
import notifyListVue from "./components/msgUI/notifyList.vue";
import appIconTransVue from "./components/global/appIconTrans.vue";
import store from "./store";
export default {
	data() {
		return {};
	},
	components: {
		msgBoxVue,
		notifyListVue,
		appIconTransVue,
	},
	computed: {},
	methods: {
		setViewPortHeight() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
	},
	created() {},
	mounted() {
		var vm = this;
		window.addEventListener("resize", () => {
			vm.setViewPortHeight();
		});
		vm.setViewPortHeight();
		store.dispatch("rootStore/setAppIconTrans", vm.$refs.appIconTran);
	},
};
</script>

<style lang="less">
</style>
