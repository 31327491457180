<template>
	<div class="views-ui-frame-wrapper">
		<menuVue />
		<div
			class="view-ui-frame-content"
			:class="{
				'menu-show': menuBtnStatus == 'back',
				'menu-hide': menuBtnStatus == 'menu',
			}"
			:style="{
				'--moveX': contentStatus.moveX + 'px',
				'--moveY': contentStatus.moveY + 'px',
				'--scale': contentStatus.scale,
			}"
		>
			<dynamicVue />
			<contentVue />
			<div
				class="view-ui-frame-content-dim"
				:class="{
					show: menuBtnStatus == 'back',
					hide: menuBtnStatus == 'menu',
				}"
				@click="setMenuStatus('hide')"
			></div>
		</div>
		<div class="views-ui-frame-menubtn">
			<button class="btn-menu" @click="onMenuBtnClick">
				<transition name="text-fade" mode="out-in">
					<span :key="menuBtnStatus">
						<i v-show="menuBtnStatus == 'menu'" class="fa-solid fa-bars"></i>
						<i
							v-show="menuBtnStatus == 'back'"
							class="fa-solid fa-arrow-left"
						></i>
					</span>
				</transition>
			</button>
		</div>
	</div>
</template>

<script>
import menuVue from "./menu.vue";
import contentVue from "./content.vue";
import dynamicVue from "./dynamic.vue";
import store from "@/store";
export default {
	name: "view-ui-frame",
	props: [],
	data() {
		return {
			contentStatus: {
				moveX: 0,
				moveY: 0,
				scale: 0.7,
			},
		};
	},
	components: {
		menuVue,
		contentVue,
		dynamicVue,
	},
	computed: {
		menuStatus: {
			get() {
				return store.state.rootStore.menuStatus;
			},
		},
		menuBtnStatus: {
			get() {
				var vm = this;
				let result = "menu";
				if (vm.menuStatus != "show") result = "menu";
				else result = "back";
				return result;
			},
		},
	},
	watch: {},
	methods: {
		setMenuStatus(input) {
			store.dispatch("rootStore/setMenuStatus", input);
		},
		onMenuBtnClick() {
			var vm = this;
			vm.setMenuMoveScale();
			if (vm.menuStatus == "show") {
				console.log("hide");
				vm.setMenuStatus("hide");
				setTimeout(() => {
					if (vm.menuStatus == "hide") vm.setMenuStatus("");
				}, 500);
			} else {
				console.log("show");
				vm.setMenuStatus("show");
			}
		},
		setMenuMoveScale() {
			var vm = this;
			let targetMenuWidth = 260;
			let windowWidth = window.innerWidth;
			let miniMainWidth = windowWidth * 0.7;
			if (windowWidth - miniMainWidth < targetMenuWidth) {
				let minus = windowWidth - miniMainWidth;
				vm.contentStatus.moveX = minus / 2 + (targetMenuWidth - minus);
			} else {
				vm.contentStatus.moveX = windowWidth - miniMainWidth;
			}
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-frame-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.views-ui-frame-menubtn {
	position: absolute;
	top: 0;
	left: 0;
	padding: 8px;
	z-index: 10;
	display: none;
}
.view-ui-frame-content {
	position: absolute;
	border-radius: 16px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

@media (max-width: 992px) {
	.views-ui-frame-menubtn {
		display: block;
	}
	.view-ui-frame-content {
		transition: 0.35s ease-in-out;
	}
	.view-ui-frame-content.menu-show {
		transform: translate(var(--moveX), var(--moveY)) scale(var(--scale));
		box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.125);
	}
	.view-ui-frame-content.menu-hide {
		transform: scale(1);
	}
	.view-ui-frame-content-dim {
		position: absolute;
		border-radius: 16px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0);
		border: solid 1px rgba(200, 200, 200.55);
		opacity: 0;
		pointer-events: none;
		display: none;
		overflow: hidden;
	}
	.view-ui-frame-content-dim.show {
		display: block;
		opacity: 1;
		pointer-events: all;
		animation: view-ui-frame-content-dim-show 0.35s ease-in-out;
	}
	@keyframes view-ui-frame-content-dim-show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	.view-ui-frame-content-dim.hide {
		display: block;
		opacity: 0;
		pointer-events: none;
		animation: view-ui-frame-content-dim-hide 0.35s ease-in-out;
	}
	@keyframes view-ui-frame-content-dim-hide {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
</style>
