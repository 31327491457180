export default {
	createGUID: createGUID,
	copyToClipboard: copyToClipboard,
	getNameToColor: getNameToColor,
};

function createGUID() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}

function copyToClipboard(input) {
	if (navigator.clipboard && window.isSecureContext) {
		return navigator.clipboard.writeText(input);
	} else {
		let textArea = document.createElement("textarea");
		textArea.value = input;
		textArea.style.position = "absolute";
		textArea.style.opacity = 0;
		textArea.style.left = "-999999px";
		textArea.style.top = "-999999px";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		return new Promise((res, rej) => {
			document.execCommand("copy") ? res() : rej();
			textArea.remove();
		});
	}
}

function getNameToColor(input) {
	var ccolor;
	let name = input;
	if (name.length > 12) name = name.substring(0, 12);

	ccolor = new Array(0, 0, 0);

	for (var i = 0; i < name.length; i++) {
		ccolor[i % 3] += name.charCodeAt(i);
	}
	ccolor[0] = colCalc(ccolor[0]);
	ccolor[1] = colCalc(ccolor[1]);
	ccolor[2] = colCalc(ccolor[2]);
	return ccolor;
}

function colCalc(i) {
	return ((i * 101) % 97) + 159;
}
