import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import StartupView from "@/views/StartupView.vue";
import TestView from "@/views/TestView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/startup",
		name: "startup",
		component: StartupView,
		meta: {
			title: "Cash Tracert",
		},
	},
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: {
			title: "Cash Tracert",
		},
	},
	{
		path: "/login",
		name: "login",
		component: LoginView,
		meta: {
			title: "Cash Tracert",
		},
	},
	{
		path: "/test",
		name: "test",
		component: TestView,
		meta: {
			title: "Cash Tracert - Test View",
		},
		children: [
			{
				path: "control",
				name: "test-control",
				component: () => import("../views-ui/test/control/main.vue"),
			},
			{
				path: "page",
				name: "test-page",
				component: () => import("../views-ui/test/page/main.vue"),
			},
			{
				path: "layer",
				name: "test-layer",
				component: () => import("../views-ui/test/layer/main.vue"),
			},
			{
				path: "msg",
				name: "test-msg",
				component: () => import("../views-ui/test/msg/main.vue"),
			},
			{
				path: "calendar",
				name: "test-calendar",
				component: () => import("../views-ui/test/calendar/main.vue"),
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name.indexOf("test") >= 0) {
		next();
		return;
	}
	if (to.name !== "startup" && !store.state.rootStore.isStartup) {
		store.dispatch("rootStore/routerStartupBack", {
			toName: to.name,
			query: to.query,
		});
		next({ name: "startup" });
		return;
	}
	if (
		to.name !== "login" &&
		to.name !== "startup" &&
		(store.state.rootStore.userEntity == null ||
			!store.state.rootStore.userEntity.IsLogin)
	) {
		store.dispatch("rootStore/routerStartupBack", {
			toName: to.name,
			query: to.query,
		});
		try {
			next({ name: "startup" });
		} catch {
			console.log("router to login return to startup");
		}
	} else {
		next();
	}
	next();
});
const DEFAULT_TITLE = "Cash Tracert";
router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || DEFAULT_TITLE;
	});
});
export default router;
