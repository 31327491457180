<template>
	<div
		class="msg-wrapper"
		v-bind:class="[wrapperstatus]"
		:style="{
			opacity: rootOpacity,
			transition: rootTrans + 's',
			transitionTimingFunction: rootTiming,
		}"
	>
		<div
			class="msg-wrapper-bg"
			:style="{
				backdropFilter: 'blur(' + wrapperBlur + 'px)',
				transition: wrapperTrans + 's',
				transitionTimingFunction: wrapperTiming,
			}"
		></div>
		<div class="msg-content-wrapper">
			<div class="msg-content-wrapper-bg" @click="onWrapperClick"></div>
			<div
				class="msg-content-frame"
				:style="{
					opacity: msgOpacity,
					transform: 'scale(' + msgZoom + ', ' + msgZoom + ')',
					pointerEvents: msgPointEvent,
					transition: msgTrans + 's',
					transitionTimingFunction: msgTiming,
				}"
			>
				<div v-if="msgData != null" class="text-center">
					<div class="row">
						<div class="col-12">
							<i
								class="fa-6x"
								v-bind:class="[msgData.icon.icon, msgData.icon.color]"
							></i>
						</div>
					</div>
					<div class="row mt-4">
						<div class="col-12">
							<h5>
								<strong>{{ msgData.msg.title }}</strong>
							</h5>
						</div>
					</div>
					<div class="row mb-4">
						<div class="col-12">
							<div
								class="msg-content-frame-msgcontent"
								v-html="msgData.msg.message"
							></div>
						</div>
					</div>
					<div class="row" v-if="msgData.btn.length == 1">
						<div class="col-12">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[0].type]"
								v-on:click="runMethod(msgData, msgData.btn[0])"
							>
								{{ msgData.btn[0].text }}
							</button>
						</div>
					</div>
					<div class="row" v-if="msgData.btn.length == 2">
						<div class="col-6 pe-2">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[1].type]"
								v-on:click="runMethod(msgData, msgData.btn[1])"
							>
								{{ msgData.btn[1].text }}
							</button>
						</div>
						<div class="col-6 ps-2">
							<button
								class="btn w-100"
								v-bind:class="[msgData.btn[0].type]"
								v-on:click="runMethod(msgData, msgData.btn[0])"
							>
								{{ msgData.btn[0].text }}
							</button>
						</div>
					</div>
					<div class="row" v-if="msgData.btn.length > 2">
						<div
							class="col-12"
							v-for="(btn, i) in msgData.btn"
							v-bind:key="i"
							v-bind:class="{
								'mt-4': i == msgData.btn.length - 1,
								'mt-2': i != msgData.btn.length - 1,
							}"
						>
							<button
								class="btn w-100"
								v-bind:class="[btn.type]"
								v-on:click="runMethod(msgData, btn)"
							>
								{{ btn.text }}
							</button>
						</div>
					</div>
				</div>
				<div class="msg-content-frame-border"></div>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import debounce from "lodash/debounce";
import msgCtrl from "@/utils/msgCtrl";
export default {
	name: "msgBox",
	props: {},
	data() {
		return {
			rootOpacity: 0,
			rootTrans: 0.5,
			rootTiming: "",
			wrapperstatus: "",
			wrapperBlur: 0,
			wrapperTrans: 0.5,
			wrapperTiming: "",
			msgZoom: 1.2,
			msgPointEvent: "none",
			msgOpacity: 0,
			msgTrans: 0.5,
			msgTiming: "",
		};
	},
	computed: {
		msgData: {
			get() {
				if (this.msgList.length > 0) return this.msgList[0];
				else return null;
			},
		},
		msgUID: {
			get() {
				if (this.msgData == null) return null;
				return this.msgData.uid;
			},
		},
		msgList: {
			get() {
				return store.state.msgStore.msgBoxList;
			},
		},
		msgCount: {
			get() {
				return store.state.msgStore.msgBoxList.length;
			},
		},
	},
	watch: {
		msgCount(newValue) {
			var vm = this;

			var hideMsg = function () {
				vm.wrapperstatus = "close";
			};
			var _debounce = debounce(hideMsg, 100);
			if (newValue > 0) {
				vm.wrapperstatus = "open";
			} else {
				_debounce();
			}
		},
		wrapperstatus() {
			this.setDim();
		},
		msgUID(newValue) {
			if (newValue != null) this.setMsgIn();
		},
	},
	methods: {
		runMethod(msg, btn) {
			if (btn.method != null) btn.method();
			this.setMsgOut(msg);
		},
		setDim() {
			if (this.wrapperstatus == "open") {
				this.rootOpacity = 1;
				this.rootTrans = 0;

				this.wrapperBlur = 8;
				this.wrapperTrans = 0.45;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootTrans = 1;
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.35;
					this.wrapperTiming = "ease-out";
				}, 450);
			} else {
				this.wrapperBlur = 0;
				this.wrapperTrans = 0.25;
				this.wrapperTiming = "ease-in";
				setTimeout(() => {
					this.rootOpacity = 0;
					this.rootTrans = 1;
				}, 250);
			}
		},
		setMsgIn() {
			this.msgZoom = 0.95;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.35;
			this.msgTiming = "ease-in";
			setTimeout(() => {
				this.msgZoom = 1;
				this.msgOpacity = 1;
				this.msgPointEvent = "all";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";
			}, 350);
		},
		setMsgOut(msg) {
			this.msgZoom = 0.9;
			this.msgOpacity = 1;
			this.msgPointEvent = "none";
			this.msgTrans = 0.15;
			this.msgTiming = "ease-in";

			this.wrapperBlur = 8;
			this.wrapperTrans = 0.15;

			setTimeout(() => {
				this.msgZoom = 1.2;
				this.msgOpacity = 0;
				this.msgPointEvent = "none";
				this.msgTrans = 0.25;
				this.msgTiming = "ease-out";

				if (this.msgCount == 1) {
					this.wrapperBlur = 0;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						msgCtrl.removeMsgBox(msg);
					}, 250);
				} else {
					this.wrapperBlur = 4;
					this.wrapperTrans = 0.25;
					setTimeout(() => {
						msgCtrl.removeMsgBox(msg);
					}, 250);
				}
			}, 200);
		},
		onWrapperClick() {
			this.msgZoom = 1.03;
			setTimeout(() => {
				this.msgZoom = 1.0;
			}, 250);
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.msg-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	transition: 0.5s;
	z-index: 999;
}

.msg-wrapper.open {
	pointer-events: all;
}

.msg-wrapper.close {
	pointer-events: none;
}

.msg-wrapper-bg {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	backdrop-filter: blur(4px);
	z-index: -1;
}

.msg-content-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.5s;
	z-index: 999;
}

.msg-content-wrapper-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

.msg-content-frame {
	position: relative;
	padding-top: 2rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	min-width: 320px;
	max-width: 85vw;
	border-radius: 16px;
	background: var(--bg-msgbox-frame);
	backdrop-filter: blur(24px);
	box-shadow: 0 1rem 3rem var(--shadow-msgbox-frame);
	pointer-events: all;
}

.msg-content-frame-border {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	border-radius: 16px;
	border: 1px solid var(--border-msgbox-frame);
	pointer-events: none;
	z-index: 999;
}

.msg-content-frame-msgcontent {
	max-height: 65vh;
	max-height: 65vh;
	max-height: calc(var(--vh, 1vh) * 65);
	overflow-y: auto;
}
.msg-content-frame-msgcontent::-webkit-scrollbar {
	display: none;
}

.msg-content-frame-msgcontent {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
</style>
