var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer-wrapper",class:[
		{
			'single-layer': _vm.isSingleLayer,
			'multi-layer': _vm.isMultiLayer,
			'top-layer': _vm.isTopLayer,
			'hide-layer': _vm.isHideLayer,
			'close-layer': _vm.isCloseLayer,
			'layer-modal': _vm.layerData.layerType == 'modal',
		},
	]},[_c('div',{staticClass:"layer-content container",class:[
			{
				'layer-modal': _vm.layerData.layerType == 'modal',
			},
		]},[_vm._t("default",function(){return [_vm._m(0)]})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12"},[_vm._v("未生成內容")])])
}]

export { render, staticRenderFns }