var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"view-login-wrapper anime-component-scale",class:[
			{
				render: _vm.statusData.loginRender,
			},
			_vm.statusData.loginStatus,
		]},[_c('loginVue',{ref:"login"})],1),_c('div',{staticClass:"view-login-wrapper anime-component-scale",class:[
			{
				render: _vm.statusData.registerRender,
			},
			_vm.statusData.registerStatus,
		]},[_c('registerVue',{ref:"register"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }