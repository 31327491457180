//Vue 2
import Vue from "vue";
import App from "./App.vue";

//CSS-Paint-PolyFill
import "css-paint-polyfill";

//PWA
import "./registerServiceWorker";

//Vue Router
import router from "./router";
//VueX
import store from "./store";

//FontAwesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

//Lodash
import _ from "lodash";

//BootStrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//Moment
import moment from "moment";
import numberConvert from "@/utils/numberConvert";

//Custom CSS
import "./assets/css/base.css";

//移除 moment 警告
moment.suppressDeprecationWarnings = true;

//註冊 paint
if (CSS && "paintWorklet" in CSS)
	CSS.paintWorklet.addModule("https://unpkg.com/smooth-corners");

//環境設定
Vue.config.productionTip = false;
new Vue({
	router,
	store,
	_,
	moment,
	render: (h) => h(App),
}).$mount("#app");

//Vue 預設 Filter
Vue.filter("numberToCurrency", numberConvert.numberToCurrencyNo);
Vue.filter("momentDate", (input) => {
	if (!input) return input;
	return moment(input).format("YYYY年MM月DD日");
});
Vue.filter("momentTime", (input) => {
	if (!input) return input;
	return moment(input).format("YYYY年MM月DD日 HH:mm:ss");
});
Vue.filter("momentDateWeek", (input) => {
	if (!input) return input;
	let d = moment(input);
	let w = "未知";
	switch (moment(input).isoWeekday()) {
		case 1:
			w = "週一";
			break;
		case 2:
			w = "週二";
			break;
		case 3:
			w = "週三";
			break;
		case 4:
			w = "週四";
			break;
		case 5:
			w = "週五";
			break;
		case 6:
			w = "週六";
			break;
		case 7:
			w = "週日";
			break;
		default:
			break;
	}
	let r = d.format("YYYY年MM月DD日") + " " + w;
	return r;
});
