<template>
	<div>
		<startupVue />
	</div>
</template>
  
<script>
import startupVue from "@/views-ui/startup/startup.vue";
export default {
	name: "StartupView",
	data() {
		return {};
	},
	components: {
		startupVue,
	},
	watch: {},
	methods: {},
	mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.startup-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	opacity: 0;
	transform: scale(0.95);
	transition: 0.35s;
	transition-timing-function: ease-in-out;
}
.startup-wrapper.show {
	opacity: 1;
	pointer-events: all;
	transform: scale(1);
	transition: 0.35s;
}
.startup-wrapper.hide {
	opacity: 0;
	pointer-events: none;
	transform: scale(0.75);
	transition: 0.25s;
}

.startup-content {
	width: 240px;
	padding-bottom: 48px;
	color: var(--text-color-hoveron);
}
@media screen and (max-width: 576px) {
	.startup-content {
		padding-bottom: 128px;
	}
}
.startup-spin {
	width: 128px;
	margin: auto;
}
</style>
