<template>
	<div class="views-ui-register-wrapper">
		<pageUIVue :pageData="pageNow" :pageItem="pageItemNow" maxWidth="680">
			<pageItemVue
				v-for="(page, i) in pageList"
				:key="i"
				:ref="'pageItem_' + i"
			>
				<component
					:is="page.componentName"
					:ref="page.componentName"
				></component>
			</pageItemVue>
		</pageUIVue>
		<div class="views-ui-register-test" v-if="false">
			<button @click="setPage('welcome')">welcome</button>
			<button @click="setPage('license')">license</button>
			<button @click="setPage('verify')">verify</button>
			<button @click="setPage('setting')">setting</button>
			<button @click="setPage('finish')">finish</button>
		</div>
	</div>
</template>
<script>
import store from "@/store";
import welcomeVue from "./register/welcome.vue";
import licenseVue from "./register/license.vue";
import verifyVue from "./register/verify.vue";
import settingVue from "./register/setting.vue";
import finishVue from "./register/finish.vue";
import pageUIVue from "@/components/pageUI/pageUI.vue";
import pageItemVue from "@/components/pageUI/pageItem.vue";
export default {
	name: "view-ui-register",
	props: [],
	data() {
		return {
			pageList: [
				{
					componentName: "welcomePage",
					name: "welcome",
				},
				{
					componentName: "licensePage",
					name: "license",
				},
				{
					componentName: "verifyPage",
					name: "verify",
				},
				{
					componentName: "settingPage",
					name: "setting",
				},
				{
					componentName: "finishPage",
					name: "finish",
				},
			],
			pageTempNow: null,
			model: {
				email: "",
				displayName: "",
				verifyUID: "",
				finishStatus: "Success",
				activeKeyList: {
					key1: "",
					key2: "",
					key3: "",
					key4: "",
					key5: "",
					key6: "",
				},
			},
		};
	},
	components: {
		welcomePage: welcomeVue,
		licensePage: licenseVue,
		verifyPage: verifyVue,
		settingPage: settingVue,
		finishPage: finishVue,
		pageUIVue,
		pageItemVue,
	},
	computed: {
		viewObj: {
			get() {
				var vm = this;
				return vm.$parent;
			},
		},
		pageNow: {
			get() {
				var vm = this;
				if (vm.pageTempNow == null) vm.pageTempNow = vm.pageList[0];
				return vm.pageTempNow;
			},
			set(value) {
				var vm = this;
				vm.pageTempNow = value;
			},
		},
		pageItemNow: {
			get() {
				var vm = this;
				let index = vm.pageList.indexOf(vm.pageNow);
				if (index < 0) return null;
				let result = vm.$refs["pageItem_" + index];
				if (result != null && result.length != 0) result = result[0];
				return result;
			},
		},
	},
	watch: {
		pageNow() {
			var vm = this;
			setTimeout(() => {
				vm.setAppIconToPage(true);
			}, 50);
		},
	},
	methods: {
		setPage(name) {
			var vm = this;
			let target = vm.pageList.filter((x) => {
				return x.name == name;
			});
			if (target && target.length > 0) target = target[0];
			if (vm.$refs[vm.pageNow.componentName][0].setUninital)
				vm.$refs[vm.pageNow.componentName][0].setUninital();
			vm.pageNow = target;
			if (vm.$refs[vm.pageNow.componentName][0].setInital)
				vm.$refs[vm.pageNow.componentName][0].setInital();
		},
		setAppIconToPage(watchPage) {
			var vm = this;
			if (vm.$refs[vm.pageNow.componentName][0].appIcon == null) {
				vm.setAppIconHide();
				return;
			} else {
				if (watchPage) {
					setTimeout(() => {
						vm.setAppIcon(vm.$refs[vm.pageNow.componentName][0].appIcon);
					}, 500);
				} else {
					vm.setAppIcon(vm.$refs[vm.pageNow.componentName][0].appIcon);
				}
			}
		},
		setAppIconHide() {
			var vm = this;
			let target = store.state.rootStore.appIconTrans;
			if (target) {
				if (target.status == "show")
					target.setFadeToTarget(vm.$refs.appIconWelcome, "hide");
			} else {
				setTimeout(() => {
					vm.setAppIconHide();
				}, 10);
			}
		},
		setAppIcon(appIcon) {
			var vm = this;
			let target = store.state.rootStore.appIconTrans;
			if (target) {
				if (target.status == "show") {
					target.setTarget(appIcon, 0.5, "show");
				} else {
					target.setFadeToTarget(appIcon, "show");
				}
			} else {
				setTimeout(() => {
					vm.setAppIcon(appIcon);
				}, 10);
			}
		},
		setInital(input) {
			var vm = this;
			vm.setPage("welcome");
			if (input) vm.model.email = input;
			vm.model.displayName = "";
			vm.model.verifyUID = "";
			vm.model.activeKeyList.key1 =
				vm.model.activeKeyList.key2 =
				vm.model.activeKeyList.key3 =
				vm.model.activeKeyList.key4 =
				vm.model.activeKeyList.key5 =
				vm.model.activeKeyList.key6 =
					"";
		},
		hideRegister() {
			var vm = this;
			vm.viewObj.hideRegister();
		},
		switchToLogin() {
			var vm = this;
			vm.viewObj.switchToLogin();
		},
	},
	created: function () {},
	mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.views-ui-register-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.views-ui-register-test {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	opacity: 0.5;
}
</style>
